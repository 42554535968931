import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ToastsStore } from 'react-toasts';
import { authentication_status } from '../helpers/bannerEndpoints';
import { configurationLoader } from '../context/brandConfig/brandConfig';
import HighlightEndpoints from '../helpers/highlightEndpoints';

export interface HighlightStateInterface {
	allHighlights: Array<any>;
	isFetching: boolean;
	editingHighlight: boolean | null;
}

const initialState: HighlightStateInterface = {
	allHighlights: [],
	isFetching: false,
	editingHighlight: null,
};

const highlightApiFactory = (state) => {
	const authToken = state?.auth?.accessToken;
	const brand = state?.application?.brand;
	const brandManagerEndpoint = configurationLoader(brand).brandManagerEndpoint;
	const highlightApi = new HighlightEndpoints(
		`${brandManagerEndpoint}/highlight`,
	);
	highlightApi.useAuthCode(authToken);
	return highlightApi;
};

export const fetchAllHighlights = createAsyncThunk(
	'highlight/fetchAllHighlights',
	async (_, thunkAPI) => {
		const highlightApi = highlightApiFactory(thunkAPI.getState());
		return { items: await highlightApi.get(authentication_status.any) };
	},
);

export const createHighlight = createAsyncThunk(
	'highlight/createHighlight',
	async (highlightItem, thunkAPI) => {
		const highlightApi = highlightApiFactory(thunkAPI.getState());
		const result = await highlightApi.createHighlight(highlightItem);
		thunkAPI.dispatch(fetchAllHighlights());

		return result;
	},
);

export const deleteHighlight = createAsyncThunk(
	'highlight/deleteHighlight',
	async (id, thunkAPI) => {
		const highlightApi = highlightApiFactory(thunkAPI.getState());

		const result = await highlightApi.deleteHighlight(id);
		thunkAPI.dispatch(fetchAllHighlights());

		return result;
	},
);

export const updateHighlight = createAsyncThunk(
	'highlight/updateHighlight',
	async (highlightItem, thunkAPI) => {
		const highlightApi = highlightApiFactory(thunkAPI.getState());

		const result = await highlightApi.updateHighlight(highlightItem);
		thunkAPI.dispatch(fetchAllHighlights());

		return result;
	},
);

const { actions, reducer } = createSlice({
	name: 'loadHighlights',
	initialState,
	reducers: {
		setHighlights(state, { payload }) {
			state = payload;
		},
		setEditingHighlight(state, { payload }) {
			// react-timepicker in create/edit form doesn't accept date format as is.
			const payloadNewTimeFormat = {
				...payload,
				expiryDate: new Date(payload.expiryDate),
			};

			state.editingHighlight = payloadNewTimeFormat;
		},
		clearEditingHighlight(state) {
			state.editingHighlight = null;
		},
	},
	extraReducers: {
		// fetchAllHighlights
		[fetchAllHighlights.pending as any]: (state) => {
			state.isFetching = true;
		},
		[fetchAllHighlights.fulfilled as any]: (state, action) => {
			state.allHighlights = action.payload.items;
			state.isFetching = false;
		},
		// createHighlight
		[createHighlight.pending as any]: (state) => {
			state.isFetching = true;
		},
		[createHighlight.fulfilled as any]: (state) => {
			ToastsStore.success('Highlight successfully created', 2000);
			state.isFetching = false;
		},
		[createHighlight.rejected as any]: (state) => {
			state.isFetching = false;
			ToastsStore.error(
				'There was a problem creating this highlighted event. Please try again',
				3000,
			);
		},
		// UpdateHighlight
		[updateHighlight.pending as any]: (state) => {
			state.isFetching = true;
		},
		[updateHighlight.fulfilled as any]: (state) => {
			ToastsStore.success('Highlight updated', 2000);
			state.isFetching = false;
		},
		[updateHighlight.rejected as any]: (state) => {
			ToastsStore.error(
				'There was a problem updating this highlight. Please try again',
				3000,
			);
			state.isFetching = false;
		},
		// deleteHighlight
		[deleteHighlight.pending as any]: (state) => {
			state.isFetching = true;
		},
		[deleteHighlight.fulfilled as any]: (state) => {
			ToastsStore.success('Highlight deleted', 2000);
			state.isFetching = false;
		},
		[deleteHighlight.rejected as any]: (state) => {
			ToastsStore.error(
				'There was a problem deleting this highlight. Please try again',
				3000,
			);
			state.isFetching = false;
		},
	},
});

export const {
	setHighlights,
	setEditingHighlight,
	clearEditingHighlight,
} = actions;
export default reducer;
