import { css } from '@emotion/core';
import React, { useLayoutEffect } from 'react';
import {
	FiDollarSign,
	FiFastForward,
	FiFileText,
	FiImage,
	FiLayers,
	FiStar,
} from 'react-icons/fi';
import { GiStairsGoal } from 'react-icons/gi';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useBrandConfig } from '../context/brandConfig/brandConfigContext';
import { FALL_OVER_BRAND } from '../context/brandConfig/brandConfig';
import styled from '../common/styled';
import Card from '../components/shared/Card';
import { Divider } from '../components/shared/FormElements';
import { setBrand } from '../store/application';

const BoxContainer = styled.div(
	() => css`
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding: -4px;
		justify-content: center;

		@media only screen and (max-width: 600px) {
			flex-direction: column;
		}
	`,
);

const Box = styled(Link)<{ disabled?: boolean }>(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		width: 250px;

		height: 100px;
		border: 1px solid #eee;
		border-radius: 4px;
		background: ${!props.disabled ? '#fff' : '#f4f4f4'};
		margin: 4px;
		font-weight: bold;
		font-size: 16px;
		cursor: ${props.disabled ? 'inherit' : 'pointer'};
		text-decoration: none;
		color: inherit;
		transition: all 0.3s;

		:hover {
			background: ${props.disabled ? '#f4f4f4' : '#e1e1e1'};
			transform: scale(1.05);
		}
		:active {
			transform: scale(0.95);
		}
		:hover svg {
			transform: rotate(5deg) rotateY(10deg);
		}

		@media only screen and (max-width: 600px) {
			width: 100%;
			margin-bottom: 8px;
			height: 50px;
		}
	`,
);

const Text = styled.div(
	() => css`
		margin-left: 6px;
	`,
);

function BrandManager({ selectedBrand, handleBrandSet: _handleBrandSet }) {
	const {
		brandConfig: localBrandConfig,
		brandList,
		authenticatedForBrand,
	} = useBrandConfig();

	const handleBrandSet = React.useCallback(_handleBrandSet, []);

	useLayoutEffect(() => {
		const selectedBrandIsValid =
			selectedBrand &&
			selectedBrand !== FALL_OVER_BRAND &&
			brandList.includes(selectedBrand);

		if (!selectedBrandIsValid && brandList.length) handleBrandSet(brandList[0]);
	}, [brandList, selectedBrand, handleBrandSet]);

	const DEPRICATED = process.env.REACT_APP_DEPRICATED;

	if (DEPRICATED)
		return (
			<Card>
				<h2>Betting App Manager</h2>
				<p>Accessing the Brand Manager app has changed.</p>
				<p>
					Please go to{' '}
					<a href="https://bm.betmakers.com">https://bm.betmakers.com</a> to
					access the Betting App Manager and update any bookmarks.
				</p>
				<p>
					This is our new global URL for accessing the Betting App Manager for
					all brands within the BetMakers system from one place. The app is the
					same as it was here - just accessed from a new URL.
				</p>
			</Card>
		);

	return (
		<Card>
			<h2>Betting App Manager</h2>
			<p>Configure your whitelabel betting app (mobile & web)</p>
			<Divider />

			{authenticatedForBrand ? (
				<BoxContainer>
					<Box to="/banners">
						<FiImage /> <Text>Banners</Text>
					</Box>
					<Box to="/content">
						<FiFileText /> <Text>Pages</Text>
					</Box>
					<Box to="/promotions">
						<FiDollarSign /> <Text>Promotions</Text>
					</Box>
					<Box to="/footer">
						<FiLayers /> <Text>Footer</Text>
					</Box>
					<Box to="/quicklinks">
						<FiFastForward /> <Text>Quicklinks</Text>
					</Box>
					{localBrandConfig?.featureFlags?.promotionEngine?.enabled && (
						<Box to="/promotion-engine">
							<GiStairsGoal /> <Text>Promotion Engine</Text>
						</Box>
					)}
					<Box to="/highlights">
						<FiStar /> <Text>Highlights</Text>
					</Box>
				</BoxContainer>
			) : (
				<div>log in first</div>
			)}
		</Card>
	);
}

const mapStateToProps = (state) => {
	return {
		selectedBrand: state.application.brand,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleBrandSet: (brand) => dispatch(setBrand(brand)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandManager);
