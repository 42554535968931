import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '../../common/styled';
import Burger from 'react-css-burger';
import { partialApply } from '../../helpers/pureFunc';

import {
	MobileMainNavGroup,
	BurgerContainer,
	MainNavGroup,
	LinkButton,
	Loading,
} from './Navs';
import LoginButton from './LoginButton';
import { useBrandConfig } from '../../context/brandConfig/brandConfigContext';
import BrandSelector from './BrandSelector';

const Container = styled('div')`
	label: Header__Container;

	height: auto;
	max-width: 100%;
	margin-bottom: ${(props) => props.theme.spacings.cozy}px;
	background-color: ${(props) => props.theme.brand.color_2};
	@media only screen and (max-width: 600px) {
		margin-bottom: 0;
	}
`;

const Content = styled('header')`
	label: Header__Content;

	max-width: 1140px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	margin: 0 auto;
`;

const Logo = styled('img')`
	label: Header__Logo;

	height: 60px;
`;

interface HeaderProps {
	loading: boolean;
	authUser: object;
	isAuthenticated: boolean;
}

const Header = ({ loading, authUser, isAuthenticated }: HeaderProps) => {
	const bmLogo =
		'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/logo.png';
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	// use partialApply to delay execution
	const menuToggle = partialApply(setIsMenuOpen, !isMenuOpen);
	const { brandConfig } = useBrandConfig();

	return (
		<Container>
			<Content>
				{isAuthenticated && <BrandSelector />}
				{!isAuthenticated && (
					<Link to="/">
						<Logo
							src={brandConfig.logo || bmLogo}
							alt={brandConfig.displayName}
						/>
					</Link>
				)}
				{loading && (
					<MainNavGroup>
						<Loading>Loading...</Loading>
					</MainNavGroup>
				)}
				{!loading && (
					<>
						<MainNavGroup>
							{isAuthenticated && (
								<LinkButton to="/" isactive={true}>
									Home
								</LinkButton>
							)}
							<LoginButton isAuth={!!authUser} />
						</MainNavGroup>
						<MobileMainNavGroup isactive={isMenuOpen}>
							{isAuthenticated && (
								<LinkButton
									to="/"
									isactive={true}
									ismobile={true}
									onClick={() => setIsMenuOpen(false)}
								>
									Home
								</LinkButton>
							)}
							<LoginButton isAuth={!!authUser} isMobile={isMenuOpen} />
						</MobileMainNavGroup>
						<BurgerContainer>
							<Burger
								onClick={menuToggle}
								active={isMenuOpen}
								burger="collapse"
								color="white"
								hoverOpacity={0.9}
								marginTop="2px"
								marginLeft="0px"
							/>
						</BurgerContainer>
					</>
				)}
			</Content>
		</Container>
	);
};

export default Header;
