import React, { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { FaFileExport } from 'react-icons/fa';
import { ToastsStore } from 'react-toasts';
import Modal from 'react-modal';

import { PromiseResolved } from '../../helpers';
import PromotionEngineEndpoints from '../../helpers/promotionEngine/PromotionEngineEndpoints';
import { promotionRedemptionsResponseType } from '../../helpers/promotionEngine/rawEndpointFunc/promotionRedemptions';
import { partialApply } from '../../helpers/pureFunc';
import { ModalStyle } from '../../common/modalStyle';
import { PromotionViewEditProps } from './PromotionEngineForms';

import { StyledButton } from '../shared/Navs';
import { Table, Td, Th, Thead, Tr } from '../shared/Table';
import { DepositFormLayout } from './DepositMatchFormComponents';
import Pagination from './Pagination';
import Card from '../shared/Card';

type pageDataType =
	| PromiseResolved<
			ReturnType<PromotionEngineEndpoints['promotionRedemptions']['execute']>
	  >
	| 'loading';

type csvDownloadStateType = null | 'loading' | 'Error' | AxiosResponse;

const pageDataIsLoading = (input: pageDataType): input is 'loading' =>
	input === 'loading';

const pageDataIsError = (input: pageDataType): input is AxiosError<any> =>
	input instanceof Error;

const pageDataIsValid = (
	input: pageDataType,
): input is AxiosResponse<promotionRedemptionsResponseType> =>
	![pageDataIsLoading, pageDataIsError].some((test) => test(input));

const csvIsNull = (input: csvDownloadStateType): input is null =>
	input === null;

const csvIsLoading = (input: csvDownloadStateType): input is 'loading' =>
	input === 'loading';

const csvIsError = (input: csvDownloadStateType): input is 'Error' =>
	input === 'Error';

const csvIsValid = (input: csvDownloadStateType): input is AxiosResponse =>
	![csvIsLoading, csvIsError, csvIsNull].some((test) => test(input));

const PromotionEngineRedeemedAreaDialog = ({
	item,
	mode,
	cachedPromotionEndpoints,
	setViewEditCloseDelete,
}: PromotionViewEditProps) => {
	const [pageSettings, setPageSettings] = useState({
		page: 1,
		totalPages: 1,
		itemsPerPage: 20,
		maxPage: 1,
	});

	const [pageData, setPageData] = useState<pageDataType>('loading');

	const [
		csvDownloadState,
		setCsvDownloadState,
	] = useState<csvDownloadStateType>(null);

	const itemId = item.id;

	const currentPage = pageSettings?.page;

	const currentItemsPerPage = pageSettings?.itemsPerPage;

	useEffect(() => {
		const replyPromise = cachedPromotionEndpoints.promotionRedemptions.execute(
			itemId,
			currentPage,
			currentItemsPerPage,
		);

		replyPromise.then((reply) => {
			if (reply instanceof Error) {
				ToastsStore.error('Failed To Fetch Promotion Redemptions');
			} else {
				setPageSettings((state) => ({
					...state,
					maxPage: reply.data.last_page,
				}));
			}

			setPageData(reply);
		});
	}, [cachedPromotionEndpoints, itemId, currentPage, currentItemsPerPage]);

	useEffect(() => {
		// download not requested or failed
		if ([csvIsNull, csvIsError].some((test) => test(csvDownloadState))) return;
		// download requested
		else if (csvIsLoading(csvDownloadState)) {
			cachedPromotionEndpoints.promotionRedemptionExport
				.execute(itemId)
				.then((result) => {
					if (result instanceof Error) {
						cachedPromotionEndpoints.promotionRedemptionExport.clearCache();
						setCsvDownloadState('Error');
						ToastsStore.error('failed to generate csv');
					} else {
						ToastsStore.success('CSV ready to download');
						setCsvDownloadState(result);
					}
				});
		}
	}, [csvDownloadState, itemId, cachedPromotionEndpoints]);

	const validPageData = pageDataIsValid(pageData) ? pageData : null;

	const pageChangeHandler = (page: number) =>
		setPageSettings((state) => ({ ...state, page }));

	const pagePreFetcher = ({ pageNumber }: { pageNumber: number }) =>
		cachedPromotionEndpoints.promotionRedemptions.execute(
			item.id,
			pageNumber,
			pageSettings.itemsPerPage,
		);

	const CSVButton = ({ isMobile }: { isMobile: boolean }) => (
		<StyledButton
			disabled={csvIsLoading(csvDownloadState)}
			onClick={
				!csvIsLoading(csvDownloadState) &&
				partialApply(setCsvDownloadState, 'loading')
			}
		>
			{!isMobile && 'Request CSV '}
			<FaFileExport className="icon-only-mobile" />
		</StyledButton>
	);

	return (
		<DepositFormLayout
			actionClose={partialApply(setViewEditCloseDelete, 'close')}
			ExtraButton={CSVButton}
			mode={mode}
		>
			<div>
				{!csvIsNull(csvDownloadState) && (
					<Modal
						isOpen={true}
						onRequestClose={partialApply(setCsvDownloadState, null)}
						style={ModalStyle}
						shouldCloseOnOverlayClick={true}
					>
						<Card isModal>
							<DepositFormLayout
								actionClose={partialApply(setCsvDownloadState, null)}
								mode={'redeem'}
							>
								<p>When File is ready, click link below to download file</p>
								{csvIsLoading(csvDownloadState) && <div>Loading</div>}
								{csvIsError(csvDownloadState) && (
									<div>Error Downloading File</div>
								)}
								{csvIsValid(csvDownloadState) && (
									<a
										href={
											'data:text/plain;charset=utf-8,' +
											encodeURIComponent(csvDownloadState.data)
										}
										download="redemptions.csv"
									>
										redemptions.csv
									</a>
								)}
							</DepositFormLayout>
						</Card>
					</Modal>
				)}
				<div>
					<Table>
						<Thead>
							<tr>
								<Th align="center">timestamp</Th>
								<Th align="center">User Id</Th>
								<Th align="center">Email Address</Th>
							</tr>
						</Thead>
						<tbody>
							{validPageData?.data?.data?.map((item, index) => {
								return (
									<Tr key={item.redeemed_at + index}>
										<Td align="center">{item.redeemed_at}</Td>
										<Td align="center">{item.user_id}</Td>
										<Td align="center">{item.email}</Td>
									</Tr>
								);
							})}
							{validPageData && (
								<Tr>
									<td colSpan={3} align="right">
										<br />
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}
										></div>
										{pageSettings?.page} of {pageSettings?.maxPage} Pages
									</td>
								</Tr>
							)}
						</tbody>
					</Table>
					<Pagination
						EitherSide={2}
						current={pageSettings.page}
						min={1}
						max={pageSettings?.maxPage || 1}
						pageRequest={pageChangeHandler}
						preFetchPage={pagePreFetcher}
					/>
				</div>
			</div>
		</DepositFormLayout>
	);
};

export default PromotionEngineRedeemedAreaDialog;
