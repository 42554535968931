import axios from 'axios';

export enum promotion_categories {
	racing = 'racing',
	sport = 'sport',
}

export interface promotionItemType {
	id: string;
	title: string;
	slug: string;
	content: string;
	category: string;
	description: string;
	expiry: string;
	route: string;
	terms_and_conditions: string;
	image: string;
}

class PromotionEndpoints {
	url: string;
	authCode: string = '';

	constructor(url: string) {
		this.url = url;
	}

	useAuthCode(code: string): PromotionEndpoints {
		this.authCode = code;
		return this;
	}
	authHeader() {
		return { Authorization: `Bearer ${this.authCode}` };
	}

	async get(status: 'all'): Promise<promotionItemType[]> {
		const builtURL = `${this.url}/${status}`;
		try {
			const response = await axios.get(builtURL, { withCredentials: false });
			const Items = (response.data.Items || []) as promotionItemType[];
			return Items;
		} catch (e) {
			console.log(e);
			return [];
		}
	}

	async fetchAllPromotions(): Promise<promotionItemType[]> {
		return await this.get('all');
	}

	async deletePromotion(id: string) {
		const builtURL = `${this.url}/${id}/delete`;
		try {
			await axios.post(
				builtURL,
				{},
				{ params: id, headers: { ...this.authHeader() } },
			);
		} catch (e) {
			console.log(e);
		}
	}

	async createPromotion(Promotion: promotionItemType) {
		const builtURL = `${this.url}/create`;
		const bodyJSON = JSON.stringify({ Item: Promotion });

		return await axios.post(builtURL, bodyJSON, {
			headers: { ...this.authHeader() },
		});
	}

	async updatePromotion(Promotion: promotionItemType) {
		const builtURL = `${this.url}/${Promotion.id}/update`;
		const bodyJSON = JSON.stringify({ Item: Promotion });

		return await axios.post(builtURL, bodyJSON, {
			headers: { ...this.authHeader() },
		});
	}
}

export default PromotionEndpoints;
