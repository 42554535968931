import parsePhoneNumberFromString from 'libphonenumber-js';
import {
	object as yObject,
	string as yString,
	Schema,
	bool as yBool,
	array as yArray,
	addMethod as yAddMethod
} from 'yup';
import { socialCodes } from '../../../helpers/footerEndpoints/TypesDefs';

import { footerItemInterface } from './FooterContentEditor/FooterContentGroups';

export const footerItemFields: { [K in keyof footerItemInterface]: any } = {
	title: yString().required(),
	href: yString().optional().url(),
	slug: yString().optional(),
};

export const footerItemValidator = yObject(footerItemFields);

export const FooterGroupFields = {
	groupName: yString().required(),
	Items: yArray(footerItemValidator),
};

export const getErrorMessage = <T>(
	yupSchema: Schema<T>,
	input,
): string | undefined => {
	try {
		yupSchema.validateSync(input);
		return;
	} catch (e) {
		return e.message;
	}
};

export const urlRequired = yString().url().required();

export const urlOptional = yString().url().notRequired();

export const ResponsibleGamblingFields = {
	title: yString().trim().required(),
	body: yString().trim().required(),
};

export const ResponsibleGamblingValidator = yObject(ResponsibleGamblingFields);


/**
 * the below adds the socialObject validator to yup
 */
yAddMethod(yObject, 'socialObject', function() {
	//@ts-ignore
    return this.test('socialObject Test', 'requires valid url or tel: link for phone', function(value) {
		const code = value.code;
		const href = value.href;
		if (code !==socialCodes.phone){
			return yObject({
				code: yString().oneOf(Object.values(socialCodes)),
				href: yString().required().url()
			}).isValidSync(value)
		}

		return parsePhoneNumberFromString(href,'AU').isValid();
    });
});

export const FooterConfigValidator = yObject({
	textColorTheme: yString().optional(),
	compactLayout: yBool().optional(),
	Items: yObject({
		Content: yObject({
			enabled: yBool().optional(),
			data: yObject({
				compactLayout: yBool().optional(),
				Items: yArray(yObject(FooterGroupFields)).required(),
			}),
		}),
		ResponsibleGambling: yObject({
			enabled: yBool().optional(),
			data: ResponsibleGamblingValidator,
		}),
		MobileApps: yObject({
			enabled: yBool().optional(),
			data: yObject({
				Items: yObject({
					ios: yObject({ href: urlOptional }).nullable(),
					android: yObject({ href: urlOptional }).nullable(),
				}).required(),
			}),
		}),
		Social: yObject({
			enabled: yBool().optional(),
			data: yObject({
				//@ts-ignore 
				Items: yArray(yObject().socialObject()),
			})
		})
	}).required(),
});
