import React, { HTMLProps } from 'react';
import styled from '../../common/styled';
import { FiInfo } from 'react-icons/fi';
import { Field } from 'formik';

interface hasErrorProp {
	isError?: boolean;
}

interface hasDisabledProp {
	disabled?: boolean;
}

interface TitleProps {
	disabled?: boolean;
}

interface DivWithWidth extends HTMLProps<HTMLDivElement> {
	width?: string;
}

export const Label = styled.label<hasErrorProp>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: ${(props) =>
		props.isError ? props.theme.ui.color_danger : 'inherit'};
	text-transform: capitalize;
`;

export const CheckboxGroup = styled.div`
	display: flex;
	flex-direction: column;
	margin: ${(props) => props.theme.spacings.compact}px 0;
`;

export const CheckboxLabel = styled.label`
	display: flex;
	margin-bottom: ${(props) => props.theme.spacings.tight}px;
	font-size: 13px;
`;

export const StyledCheckbox = styled(Field)`
	label: FormElements__Checkbox;

	margin-left: 0;
	margin-bottom: 0;
	margin-top: 2px;
	margin-right: ${(props) => props.theme.spacings.compact}px;
	appearance: none;
	border-radius: 4px;
	border: 1px solid #ddd;
	width: 15px;
	box-shadow: none;
	outline: none;

	&:checked {
		background: ${(props) => props.theme.brand.color_1};
		border: 1px solid ${(props) => props.theme.brand.color_1};
	}

	&:focus {
		outline: 1px dashed #f4f4f4;
	}
`;

export const Input = styled.input<hasErrorProp>`
	box-shadow: none;
	appearance: none;
	margin: 0 auto;
	width: 100%;
	height: 3em;
	padding: ${(props) => props.theme.spacings.compact}px;
	border: 1px solid ${(props) => props.theme.ui.color_4};
	box-sizing: border-box;
	outline: none;
	color: ${(props) =>
		props.isError ? props.theme.ui.color_danger : 'inherit'};
	transition: all 0.3s;
	text-transform: none;

	&[disabled] {
		background: #ccc;
		color: #222;
		transition: all 0.3s;
	}

	& span {
		display: block;
	}

	&.thin {
		max-width: 150px;
		margin: 0;
	}
`;

export const Textarea = styled.textarea<hasErrorProp>`
	box-shadow: none;
	appearance: none;
	resize: vertical;
	overflow-x: hidden;
	overflow-y: scroll;
	margin: 0 auto;

	width: 100%;
	min-height: calc(6 * 3em);

	padding: ${(props) => props.theme.spacings.compact}px;
	border: 1px solid ${(props) => props.theme.ui.color_4};
	box-sizing: border-box;
	outline: none;
	color: ${(props) =>
		props.isError ? props.theme.ui.color_danger : 'inherit'};
	transition: all 0.3s;
	text-transform: none;

	&[disabled] {
		background: #ccc;
		color: #222;
		transition: all 0.3s;
	}

	& span {
		display: block;
	}

	&.thin {
		max-width: 150px;
		margin: 0;
	}
`;

export const InputCheckBox = styled.input<hasErrorProp>`
	margin: 0;
`;

export const Select = styled.select<hasErrorProp>`
	box-shadow: none;
	appearance: none;
	margin: 0 auto;
	width: 100%;
	padding: ${(props) => props.theme.spacings.compact}px;
	border: 1px solid ${(props) => props.theme.ui.color_4};
	box-sizing: border-box;
	outline: none;
	color: ${(props) => (props.isError ? props.theme.ui.color_danger : '#222')};
	transition: all 0.3s;

	&.thin {
		max-width: 150px;
		margin: 0;
	}

	&[disabled] {
		background: #ccc;
		transition: all 0.3s;
	}
`;

export const Holder = styled('div')`
	box-sizing: border-box;
	width: ${({ width }: DivWithWidth) => width};
	padding: ${({ theme: { spacings } }) => spacings.compact}px;
`;

export const Form = styled('form')`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`;

export const SplitView = styled.div<hasDisabledProp>`
	display: flex;
	flex-direction: ${(props) => (props.disabled ? 'column' : 'row')};
	width: 100%;
`;

export const LeftColumn = styled.div<hasDisabledProp>`
	display: flex;
	width: ${(props) => (props.disabled ? '100%' : '50%')};
	margin-right: ${(props) => props.theme.spacings.tight}px;
`;

export const RightColumn = styled.div<hasDisabledProp>`
	display: flex;
	width: ${(props) => (props.disabled ? '100%' : '50%')};
	margin-left: ${(props) => props.theme.spacings.tight}px;
`;

export const Title = styled.span<TitleProps>`
	margin-bottom: ${(props) => props.theme.spacings.compact}px;
	color: ${(props) => (props.disabled ? '#838383' : 'inherit')};
	text-transform: capitalize;
`;

export const ErrorSpan = styled.span<hasErrorProp>`
	font-size: 12px;
	font-style: italic;
	margin-top: ${(props) => props.theme.spacings.tight}px;
	margin-bottom: ${(props) => props.theme.spacings.tight}px;
	color: ${(props) =>
		props.isError ? props.theme.ui.color_danger : 'inherit'};
	visibility: ${(props) => (props.isError ? 'unset' : 'hidden')};
`;

export const Hint = styled.span`
	font-size: 12px;
	font-style: italic;
	margin-top: -${(props) => props.theme.spacings.tight}px;
	margin-bottom: ${(props) => props.theme.spacings.tight}px;
	color: #838383;
	user-select: none;
	height: 1.5em;
	text-transform: none;
`;

export const Divider = styled.div`
	border: 0.5px solid #f2f2f2;
	margin: ${(props) => props.theme.spacings.cozy}px 0;
`;

export const InfoContainer = styled.span`
	margin-bottom: -45px;
`;

export const LabelContainer = styled.div`
	margin: 0 auto ${(props) => props.theme.spacings.cozy}px;
	width: 100%;
	padding: ${(props) => props.theme.spacings.compact}px;
`;

export const LabelWithError = ({
	htmlFor,
	disabled,
	title,
	hint,
	children,
	error,
	hasToolTip,
	toolTipFor,
}: {
	htmlFor: string;
	title: string;
	hint?: string;
	disabled?: boolean;
	children?: any;
	error: string | undefined;
	hasToolTip?: boolean;
	toolTipFor?: string;
}) => (
	<LabelContainer>
		<Label htmlFor={htmlFor}>
			<Title disabled={disabled}>
				{title}{' '}
				{hasToolTip && (
					<InfoContainer>
						<FiInfo
							data-tip
							data-for={toolTipFor}
							color="#666666"
							size={'0.7em'}
						/>
					</InfoContainer>
				)}
			</Title>
			{hint && <Hint>{hint}</Hint>}
		</Label>
		{children}

		{error && <ErrorSpan isError={true}>{error || '\u2063'}</ErrorSpan>}
	</LabelContainer>
);

const SearchBoxDiv = styled.div`
	flex: 0 0 auto;
	position: relative;
	border-radius: 16px;
	border: 1px solid ${(props) => props.theme.ui.color_info};
	overflow: hidden;
	margin-left: 10px;
	margin-right: 10px;
	transition: flex-grow 0.9s cubic-bezier(0.66, -0.1, 0.28, 1.04);

	input {
		display: inline-block;
		text-align: center;
		margin: 0 15px;
		border: none;
		box-sizing: border-box;
		width: auto;
		margin: 0px;
		height: 1.5em;
		margin-left: calc(1em + 5px);
	}
	input:focus {
		width: 100%;
	}

	&:focus-within,
	&:hover {
		box-shadow: 0px 0px 4px 2px ${(props) => props.theme.ui.color_info};
	}
	&:focus-within {
		flex: 1 1 auto;
	}
	&::before {
		display: inline-block;
		position: absolute;
		left: 10px;
		top: calc(0.75em - 0.55em);
		height: 1em;
		/* height: 0.6em; */
		/* this uri is the same as import { FaSearch } from 'react-icons/fa'; */
		content: url("data:image/svg+xml;charset=UTF-8, <svg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'><path d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'></path></svg>");
		z-index: 0;
		pointer-events: none;
	}
`;

export const SearchBox = ({ ...props }: HTMLProps<HTMLInputElement>) => {
	return (
		<SearchBoxDiv>
			<Input {...props} />
		</SearchBoxDiv>
	);
};
