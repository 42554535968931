import { PromotionEngineItem } from './rawEndpointFunc/common';

export enum promotionTriggerTypes {
	DEPOSIT = 1,
	UNKNOWN = 'UNKNOWN',
}

export enum promotionRewardTypes {
	BONUS_BETS = 1,
}

export enum promotionEngineClassEvents {
	fetch = 'PromotionEngineFetch',
	update = 'PromotionEngineUpdate',
	create = 'PromotionEngineCreate',
	delete = 'PromotionEngineDelete',
	mutationStart = 'PromotionEngineMutationStart',
	mutationFinish = 'PromotionEngineMutationFinish',
}

export interface promotionItemInterface extends PromotionEngineItem {
	reward_type?: promotionRewardTypes.BONUS_BETS;
}
