import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from '../../../../common/styled';
import {
	Holder,
	Input,
	Textarea,
	LabelWithError,
} from '../../../shared/FormElements';
import {
	getErrorMessage,
	ResponsibleGamblingFields,
} from '../FooterValidation';
import { configItemProp } from '../index';

const toolTipConfig = {
	backgroundColor: '#000',
	delayShow: 100,
	multiLine: true,
};

interface props extends configItemProp {
	title: string;
	body: string;
}

export const FooterResponsibleGamblingEditor = ({
	title,
	body,
	setField,
}: props) => {
	const [errors, setErrors] = useState(null);
	useEffect(() => {
		setErrors({
			title: getErrorMessage(ResponsibleGamblingFields.title, title),
			body: getErrorMessage(ResponsibleGamblingFields.body, body),
		});
	}, [title, body]);

	return (
		<StyledFooterResponsibleGamblingEditor>
			<Holder width="100%">
				<LabelWithError
					title="Title"
					htmlFor="title"
					error={errors?.title || ''}
					hint="Example: Must Be 18+"
					hasToolTip={true}
					toolTipFor="ResponsibleTitleTip"
				>
					<Input
						type="text"
						id="title"
						name="title"
						value={title}
						onChange={(e) => setField(['title'], e.target.value, true)}
						placeholder="Enter A Title"
					/>
					<ReactTooltip
						id="ResponsibleTitleTip"
						effect="solid"
						{...toolTipConfig}
					>
						This is the Title of the Responsible Gambling Message that appears
						within the Footer.
					</ReactTooltip>
				</LabelWithError>
			</Holder>
			<Holder width="100%">
				<LabelWithError
					title="body"
					htmlFor="body"
					hint="Example: Think About Your Choices Call Gambling Help 1800 858 858 Responsible Gambling"
					hasToolTip={true}
					toolTipFor="ResponsibleBodyTip"
					error={errors?.body || ''}
				>
					<Textarea
						aria-multiline="true"
						value={body}
						onChange={(e) => setField(['body'], e.target.value, true)}
						placeholder="Enter a Responsible Gambling Message to Appear In Footer"
					/>
				</LabelWithError>
				<ReactTooltip id="ResponsibleBodyTip" effect="solid" {...toolTipConfig}>
					This is the Content of the Responsible Gambling Message that appears
					within the Footer.
				</ReactTooltip>
			</Holder>
		</StyledFooterResponsibleGamblingEditor>
	);
};

const StyledFooterResponsibleGamblingEditor = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

export default FooterResponsibleGamblingEditor;
