import {
	promotionEngineClassEvents,
	promotionItemInterface,
} from './promotionEngineInterfaces';
import PromotionEngineEndpoints from './PromotionEngineEndpoints';
import {
	PromotionEngineSortByKeysArray,
	ListPromotionResponse,
} from './rawEndpointFunc/promotionsList';

import { PromiseResolved } from '..';
import Axios from 'axios';

type PromotionEngineListener<event> = (
	eventName: event,
	data: any,
	APIHandler: PromotionEngineAPI,
) => void;

class PromotionEngineAPI {
	readonly url: string;
	endpoints: PromotionEngineEndpoints;
	promotionListPageParams: {
		page: number;
		items: number;
		sortKeys: PromotionEngineSortByKeysArray;
		searchTerm?: string;
	};
	dataCurrent: boolean = false;
	public updateAuth(authToken: string) {
		this.endpoints.updateAuthToken(authToken);
		return this;
	}
	private _data: ListPromotionResponse | null = null;
	private listeners: {
		[K in promotionEngineClassEvents]?: Set<
			PromotionEngineListener<promotionEngineClassEvents>
		>;
	} = {};

	constructor(
		url: string,
		mockAxiosCreate: typeof Axios.create = Axios.create,
	) {
		this.url = url;
		this.endpoints = new PromotionEngineEndpoints(url, mockAxiosCreate);
		this.promotionListPageParams = {
			sortKeys: [{ key: 'id', dir: 'asc' }],
			page: 1,
			items: 10,
			searchTerm: null,
		};
		this._data = null;
	}

	get PromotionEngineListPageData(): {
		pageParams: {
			page: number;
			items: number;
			sortKeys: PromotionEngineSortByKeysArray;
			searchTerm?: string;
		};
		data: ListPromotionResponse | null;
	} {
		return {
			pageParams: this.promotionListPageParams,
			data: this._data,
		};
	}
	PromotionSetSearchTerm(term?: string) {
		this.promotionListPageParams = {
			...this.promotionListPageParams,
			page: 1,
			searchTerm: term || '',
		};
		return this;
	}
	PromotionSetSortKey(key: PromotionEngineSortByKeysArray[number]['key']) {
		const previousKey = this.promotionListPageParams.sortKeys?.[0];

		if (previousKey?.key === key)
			this.promotionListPageParams.sortKeys = [
				{ key, dir: previousKey.dir === 'asc' ? 'desc' : 'asc' },
			];
		else this.promotionListPageParams.sortKeys = [{ key, dir: 'asc' }];

		return this;
	}

	PromotionSortKeysReset() {
		this.promotionListPageParams.sortKeys = [{ key: 'id', dir: 'asc' }];
		this.dataCurrent = false;
		return this;
	}

	setPromotionPageRange(
		page: number = 1,
		itemsPerPage: number = this.PromotionEngineListPageData.pageParams.items,
	) {
		if (
			page !== this.promotionListPageParams.page ||
			this.promotionListPageParams.items !== itemsPerPage
		)
			this.dataCurrent = false;
		this.promotionListPageParams.page = page;
		this.promotionListPageParams.items = itemsPerPage;
		return this;
	}
	async preFetchPromotions(
		page: number,
		items: number = this.promotionListPageParams.items,
		sortKeys: PromotionEngineSortByKeysArray = this.promotionListPageParams
			.sortKeys,
		search: string = this.promotionListPageParams.searchTerm,
	) {
		await this.endpoints.promotionsList.execute(page, items, sortKeys, search);
		return this;
	}
	get validationPromotion() {
		return this.endpoints.promotionValidate;
	}
	async executeFetchPromotions(): Promise<
		PromiseResolved<
			ReturnType<PromotionEngineEndpoints['promotionsList']['execute']>
		>
	> {
		this.omit(promotionEngineClassEvents.mutationStart, null);

		const result = await this.endpoints.promotionsList.execute(
			this.promotionListPageParams.page,
			this.promotionListPageParams.items,
			this.promotionListPageParams.sortKeys,
			this.promotionListPageParams.searchTerm,
		);

		if (result instanceof Error) {
			this.omit(promotionEngineClassEvents.mutationFinish, null);
			console.log('failed to fetch items', result);
		} else {
			this._data = result.data;
			this.dataCurrent = true;
			this.omit(
				promotionEngineClassEvents.fetch,
				this.PromotionEngineListPageData,
			);
		}

		this.omit(promotionEngineClassEvents.mutationFinish, null);

		return result;
	}

	async createPromotion(
		input: Omit<promotionItemInterface, 'id'>,
	): Promise<
		PromiseResolved<ReturnType<PromotionEngineEndpoints['promotionCreate']>>
	> {
		this.omit(promotionEngineClassEvents.mutationStart, null);
		const result = await this.endpoints.promotionCreate(input);

		if (result instanceof Error) {
			console.log('failed to create promotion', result);
		} else {
			this.omit(promotionEngineClassEvents.create, result?.data?.data);
			this.endpoints.promotionClientList.clearCache();
			await this.executeFetchPromotions();
		}

		this.omit(promotionEngineClassEvents.mutationFinish, null);
		return result;
	}

	async editPromotion(
		input: promotionItemInterface,
	): Promise<
		PromiseResolved<ReturnType<PromotionEngineEndpoints['promotionUpdate']>>
	> {
		this.omit(promotionEngineClassEvents.mutationStart, null);

		const result = await this.endpoints.promotionUpdate(input);
		if (result instanceof Error) {
			if (result.response.status === 403) {
				// item missing
				this.endpoints.clearCache();
				await this.executeFetchPromotions();
			}
			console.log('failed to update the promotion item', result);
		} else {
			this.dataCurrent = false;
			this.endpoints.clearCache();
			await this.executeFetchPromotions();
			this.omit(promotionEngineClassEvents.update, input);
		}

		this.omit(promotionEngineClassEvents.mutationFinish, null);
		return result;
	}

	async deletePromotion(
		id: promotionItemInterface['id'],
	): Promise<
		PromiseResolved<ReturnType<PromotionEngineEndpoints['promotionDelete']>>
	> {
		this.omit(promotionEngineClassEvents.mutationStart, null);
		const result = await this.endpoints.promotionDelete(id);

		if (result instanceof Error) {
			console.log('failed to delete item', result);
			if (result.response.status === 403) {
				this.endpoints.clearCache();
				await this.executeFetchPromotions();
			}
		} else {
			this.endpoints.clearCache();
			this.omit(promotionEngineClassEvents.delete, id);
			this.dataCurrent = false;
			await this.executeFetchPromotions();
			this.omit(promotionEngineClassEvents.mutationFinish, null);
		}

		return result;
	}

	// pub sub model interface
	private omit(eventName: promotionEngineClassEvents, data: any) {
		this.listeners?.[eventName]?.forEach?.((listener) => {
			listener(eventName, JSON.parse(JSON.stringify(data || '')), this);
		});
	}

	subscribe<T extends promotionEngineClassEvents>(
		eventName: T | T[],
		listener: PromotionEngineListener<T>,
	) {
		if (!Array.isArray(eventName)) {
			if (!this.listeners[eventName]) this.listeners[eventName] = new Set();
			this.listeners[eventName].add(listener);
		} else eventName.forEach((e) => this.subscribe(e, listener));
	}

	unsubscribe<T extends promotionEngineClassEvents>(
		eventName: T | T[], // promotionEngineClassEvents | promotionEngineClassEvents[],
		listener: PromotionEngineListener<T>, //typeof eventName>,
	) {
		if (!Array.isArray(eventName)) {
			if (!this.listeners[eventName]) return;

			if (this.listeners[eventName].has(listener))
				this.listeners[eventName].delete(listener);
		} else eventName.forEach((e) => this.unsubscribe(e, listener));
	}
}

export default PromotionEngineAPI;
