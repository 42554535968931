import React, { createContext, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { MasterState } from '../../store/index';
import {
	BrandNameType,
	brandConfigLoader,
	useableBrandConfigInterface,
	brandsList,
	FALL_OVER_BRAND,
} from './brandConfig';

export interface BrandConfigContextValue {
	brandConfig: useableBrandConfigInterface;
	brandName: BrandNameType | typeof FALL_OVER_BRAND;
	brandList: BrandNameType[];
	authenticatedForBrand: boolean;
}

const BrandConfigContext = createContext<BrandConfigContextValue>(null);

function useBrandConfig() {
	const context = useContext(BrandConfigContext);
	return context;
}

interface BrandConfigProviderProps {
	children: React.ReactNode;
	brandName: BrandNameType | typeof FALL_OVER_BRAND;
}

const authSelector = (state) => state?.auth;

function BrandConfigProvider({
	children,
	brandName,
}: BrandConfigProviderProps) {
	const authDetails = useSelector<MasterState, MasterState['auth']>(
		authSelector,
	);

	const isAuthenticated = authDetails?.isAuthenticated;

	const groups = authDetails?.user?.groups || [];

	const calculatedBrandConfig = useMemo((): BrandConfigContextValue => {
		const authorizedBrandList = groups.includes('BETMAKERS_STAFF')
			? [...brandsList]
			: brandsList.filter((brand) =>
					groups.includes(brand === 'realbookieau' ? 'realbookie' : brand),
			  );

		const authenticatedForBrand =
			isAuthenticated &&
			brandName &&
			brandName !== FALL_OVER_BRAND &&
			authorizedBrandList.includes(brandName);

		return {
			brandConfig: brandConfigLoader(
				authenticatedForBrand ? brandName : FALL_OVER_BRAND,
			),
			brandName,
			authenticatedForBrand,
			brandList: authorizedBrandList,
		};
	}, [groups, isAuthenticated, brandName]);

	return (
		<BrandConfigContext.Provider value={calculatedBrandConfig}>
			{children}
		</BrandConfigContext.Provider>
	);
}

function withBrandConfig<P extends BrandConfigContextValue>(
	El: React.FC<
		Omit<P, 'brandConfig' | 'brandName' | 'brandList' | 'authenticatedForBrand'>
	>,
): React.FC<P> {
	return ({ ...props }) => {
		const { brandConfig, brandName } = useBrandConfig();
		return <El brandConfig={brandConfig} brandName={brandName} {...props} />;
	};
}

export {
	useBrandConfig,
	BrandConfigProvider,
	BrandConfigContext as default,
	brandsList,
	withBrandConfig,
};
