import React from 'react';
import { useFormik } from 'formik';
import { FiSave, FiX } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import { ToastsStore } from 'react-toasts';
import { useTheme } from 'emotion-theming';

import { bannersValidation } from '../../common/validations';

import { Holder, LabelWithError, Input, Select } from '../shared/FormElements';
import { NavGroup, StyledButton } from '../shared/Navs';
import Card from '../shared/Card';
import PageHeader from '../shared/PageHeader';
import UserImageUpload from '../shared/UserImageUpload';

const BannerCreate = ({ handleCreate, closeModal, context, bannerToEdit }) => {
	const initialValues = bannerToEdit
		? bannerToEdit
		: {
				visible: true,
				authentication_status: 'signed_in',
				link: '',
				image_src: '',
				image_src_mobile: '',
				image_width: null,
				banner_type: context,
				context_id: '',
		  };

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: true,
		validateOnBlur: true,
		validationSchema: bannersValidation,
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			setSubmitting(false);
			await handleCreate(values, values.banner_type);
			closeModal();
			resetForm();
		},
	});

	const { banner_type } = formik.values;
	const needsContextId = ['race', 'event'].includes(banner_type);

	// Titles for contextId field
	const bannerTypeTitles = {
		race: 'Race or Meeting ID',
		event: 'Event ID',
	};

	const toolTipConfig = {
		backgroundColor: '#000',
		delayShow: 100,
		multiLine: true,
		delayHide: 1000,
	};

	const theme = useTheme();

	return (
		<Card isModal>
			<form onSubmit={formik.handleSubmit}>
				<PageHeader title="Create Banner">
					<NavGroup>
						<StyledButton isactive="true" type="submit">
							Save <FiSave />
						</StyledButton>
						<StyledButton onClick={closeModal}>
							Close <FiX />
						</StyledButton>
					</NavGroup>
				</PageHeader>
				<Holder>
					<LabelWithError
						htmlFor={formik.values.link}
						title="URL banner link"
						error={formik.errors.link}
					>
						<Input
							id="link"
							value={formik.values.link}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
					</LabelWithError>
					<LabelWithError
						htmlFor={formik.values.image_src}
						title="Desktop Image"
						error={formik.errors.image_src}
						hint={'Must be exactly 1170 x 150 pixels in size'}
					>
						<UserImageUpload
							folder="banner"
							selectedImage={formik.values.image_src}
							setSelectedImage={(newImageUrl) =>
								formik.setFieldValue('image_src', newImageUrl, true)
							}
							getObjectMeta={(obj) =>
								formik.setFieldValue('image_width', obj.width)
							}
							validationTest={(obj) => {
								// if (obj.meta.width !== 1170 || obj.meta.height !== 150) {
								// 	ToastsStore.error(
								// 		'Incorrect image dimensions. Must be 1170 x 150 pixels.',
								// 		6000,
								// 	);
								// 	obj.remove();
								// 	return true;
								// }
							}}
						/>
					</LabelWithError>
					<LabelWithError
						htmlFor={formik.values.image_src_mobile}
						title="Mobile Image"
						error={formik.errors.image_src_mobile}
						hint={'Must be exactly 1170 x 440 pixels in size'}
					>
						<UserImageUpload
							folder="banner"
							selectedImage={formik.values.image_src_mobile}
							setSelectedImage={(newImageUrl) =>
								formik.setFieldValue('image_src_mobile', newImageUrl, true)
							}
							validationTest={(obj) => {
								if (obj.meta.width !== 1170 || obj.meta.height !== 440) {
									ToastsStore.error(
										'Incorrect image dimensions. Must be 1170 x 440 pixels.',
										6000,
									);
									obj.remove();
									return true;
								}
							}}
						/>
					</LabelWithError>
					<LabelWithError
						htmlFor={formik.values.authentication_status}
						title="Display"
						error={formik.errors.authentication_status}
						hasToolTip={true}
						toolTipFor={'bannerDisplayTip'}
					>
						<Select
							name="authentication_status"
							className="thin"
							value={formik.values.authentication_status}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isError={false}
						>
							<option value="signed_in" label="Logged In Users" />
							<option value="signed_out" label="Guests Only" />
							<option value="any" label="All" />
						</Select>
					</LabelWithError>
					<LabelWithError
						htmlFor={formik.values.banner_type}
						title="Banner location"
						error={formik.errors.banner_type}
						hasToolTip={true}
						toolTipFor={'bannerContextIdTip'}
					>
						<Select
							name="banner_type"
							className="thin"
							value={formik.values.banner_type}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isError={false}
						>
							<option value="homepage" label="Homepage" />
							<option value="racing" label="Racing Homepage" />
							<option value="race" label="Race Or Meeting" />
							<option value="sports" label="Sports Homepage" />
							<option value="event" label="Sport or Event" />
						</Select>
					</LabelWithError>
					{needsContextId && (
						<LabelWithError
							htmlFor={formik.values.context_id}
							title={bannerTypeTitles[formik.values.banner_type]}
							error={formik.errors.context_id}
							hint="Required for banner to be visible"
						>
							<Input
								id="context_id"
								className="thin"
								value={formik.values.context_id}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</LabelWithError>
					)}
				</Holder>
				<ReactTooltip
					id="bannerDisplayTip"
					effect="solid"
					{...toolTipConfig}
					getContent={() => (
						<div>
							Due to gambling regulation laws, some banners <br />
							must only be visible to signed in users.
							<br />
							<a
								style={{ color: theme.brand.color_1 }}
								href="https://www.acma.gov.au/gambling-ads-during-live-sport-streamed-over-internet"
							>
								Learn more
							</a>
						</div>
					)}
				></ReactTooltip>
				<ReactTooltip id="bannerContextIdTip" effect="solid" {...toolTipConfig}>
					Race or Meeting Banners can display for single races or entire race
					meetings.
					<br />
					i.e. Meeting banners will show for every race in that meeting.
					<br />
					<br />
					Sport or Event Banners can display for single events or whole sport
					categories.
					<br />
					Add event ID for single events.
					<br />
					Add sport name as displayed in the address bar when viewing the sport
					(e.g. rugby-league).
				</ReactTooltip>
			</form>
		</Card>
	);
};
export default BannerCreate;
