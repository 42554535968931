export const FALL_OVER_BRAND = 'NOT_LOGGED_IN' as const;

export const brandsList = [
	'master',
	'benchmark',
	'integration',
	'bbet',
	'betdeluxe',
	'wat',
	'realbookie',
	'realbookieau',
	'crossbet',
	'texbet',
	'picnicbet',
	'pla',
	'zbet',
	'caymanasbet',
	'monmouth',
	'wish',
] as const;

export type BrandNameType = typeof brandsList[number];

const featureNames = ['promotionEngine'] as const;

export type brandType = typeof brandsList[number];
type featureNameType = typeof featureNames[number];

type propertyByBrand<T> = {
	[K in brandType]: T;
} &
	{
		[K in typeof FALL_OVER_BRAND]?: T;
	};
type propertyByBrandOptional<T> = { [K in brandType]?: T } &
	{ [K in typeof FALL_OVER_BRAND]?: T };

interface featureConfig<T = any> {
	enabled: boolean;
	config: T;
}

type featureFlagsByBrand = propertyByBrandOptional<
	{ [K in featureNameType]?: featureConfig }
>;

export interface brandConfigType {
	displayName: propertyByBrand<string>;
	logo: propertyByBrand<string>;
	favicon: propertyByBrand<string>;
	brandManagerEndpoint: propertyByBrand<string>;
	appUrl: propertyByBrand<string>;
	primaryColors: propertyByBrand<string>;
	secondaryColors: propertyByBrand<string>;
	bettingEngineLegacyApi: propertyByBrand<string>;
	aclBrandCode: propertyByBrand<number>;
	featureFlags: featureFlagsByBrand;
}

export const brandFeatureConfig = (
	brand: brandType,
	featureName: featureNameType,
): featureConfig | undefined =>
	brandConfig['featureFlags']?.[brand.toLowerCase()]?.[featureName];

export const brandConfig: brandConfigType = {
	displayName: {
		master: 'Mugbookie',
		benchmark: 'Benchmark',
		integration: 'Integration',
		bbet: 'BBet',
		betdeluxe: 'BetDeluxe',
		wat: 'RobWaterhouse',
		realbookie: 'RealBookie - USA',
		realbookieau: 'RealBookie - AU',
		crossbet: 'CrossBet',
		texbet: 'TexBet',
		picnicbet: 'PicnicBet',
		pla: 'PlayaBet',
		zbet: 'ZBet',
		caymanasbet: 'CaymanasBet',
		monmouth: 'Monmouth',
		wish: 'WishBet',
	},
	logo: {
		NOT_LOGGED_IN:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/logo.png',
		master:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/logo.png',
		benchmark:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/logo.png',
		integration:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/logo.png',
		bbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/bbet/logo.png',
		betdeluxe:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betdeluxe/logo.png',
		wat:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/robwaterhouse/logo.png',
		realbookie:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/realbookie/logo.png',
		realbookieau:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/realbookie/logo.png',
		crossbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/crossbet/logo.png',
		texbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/texbet/logo.png',
		picnicbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/picnicbet/logo.png',
		pla:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/playabet/logo.png',
		zbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/zbet/logo.png',
		caymanasbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/caymanasbet/logo.png',
		monmouth:
			'https://bloximages.chicago2.vip.townnews.com/saratogian.com/content/tncms/assets/v3/editorial/4/37/437f4e0a-b0b2-11e9-81cf-d700124aa925/5d3cbc99704a5.image.png?resize=935%2C200',
		wish:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/wishbet/logo.png',
	},
	favicon: {
		NOT_LOGGED_IN:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/favicon.ico',
		master:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/favicon.ico',
		benchmark:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/favicon.ico',
		integration:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/favicon.ico',
		bbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/bbet/favicon.ico',
		betdeluxe:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betdeluxe/favicon.png',
		wat:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/robwaterhouse/favicon.png',
		realbookie:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/realbookie/favicon.png',
		realbookieau:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/realbookie/favicon.png',
		crossbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/crossbet/favicon.png',
		texbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/texbet/favicon.png',
		picnicbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/picnicbet/favicon.png',
		pla:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/playabet/favicon.ico',
		zbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/zbet/favicon.png',
		caymanasbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/caymanasbet/favicon.png',
		monmouth:
			'https://www.monmouthpark.com/wp-content/themes/monmouth/images/favicon.jpg',
		wish:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/wishbet/favicon.png',
	},
	brandManagerEndpoint: {
		master: 'https://cms.mugbookie.com',
		benchmark: 'https://cms.benchmark.ext.thebetmakers.com',
		integration: 'https://cms.integration.ext.thebetmakers.com',
		bbet: 'https://cms.bbet.com.au',
		betdeluxe: 'https://cms.betdeluxe.com.au',
		wat: 'https://cms.robwaterhouse.com',
		realbookie: 'https://cms.realbookie.com.au',
		realbookieau: 'https://cms.realbookie.ext.thebetmakers.com',
		crossbet: 'https://cms.crossbet.com.au',
		texbet: 'https://cms.texbet.com.au',
		picnicbet: 'https://cms.picnicbet.com',
		pla: 'https://cms.pla.ext.thebetmakers.com',
		zbet: 'https://cms.zbet.com.au',
		caymanasbet: 'https://cms.caymanasbet.ext.thebetmakers.com',
		monmouth: 'https://cms.monmouth.ext.thebetmakers.com',
		wish: 'https://cms.wishbet.com.au',
	},
	appUrl: {
		master: 'https://master.mugbookie.com',
		benchmark: 'https://benchmark.thebetmakers.com',
		integration: 'https://integration.thebetmakers.com',
		bbet: 'https://bbet.com.au',
		betdeluxe: 'https://betdeluxe.com.au',
		wat: 'https://robwaterhouse.com',
		realbookie: 'https://realbookie.com.au',
		realbookieau: 'https://realbookie.ext.thebetmakers.com',
		crossbet: 'https://crossbet.com.au',
		texbet: 'https://texbet.com.au',
		picnicbet: 'https://picnicbet.com',
		pla: 'https://pla.ext.thebetmakers.com',
		zbet: 'https://zbet.com.au',
		caymanasbet: 'https://caymanasbet.ext.thebetmakers.com',
		monmouth: 'https://monmouth.ext.thebetmakers.com',
		wish: 'https://wishbet.com.au',
	},
	primaryColors: {
		NOT_LOGGED_IN: '#ff0080',
		master: '#ff0080',
		benchmark: '#ff0080',
		integration: '#ff0080',
		bbet: '#ff5c3e',
		betdeluxe: '#f37168',
		wat: '#333333',
		realbookie: '#008c95',
		realbookieau: '#008c95',
		crossbet: '#51b0a0',
		texbet: '#9adf3e',
		picnicbet: '#ffbd59',
		pla: '#1f1e1e',
		zbet: '#4070f6',
		caymanasbet: '#ffcd00',
		monmouth: '#2e5746',
		wish: '#efb121',
	},
	secondaryColors: {
		NOT_LOGGED_IN: '#293842',
		master: '#293842',
		benchmark: '#293842',
		integration: '#293842',
		bbet: '#084c8b',
		betdeluxe: '#000000',
		wat: '#000000',
		realbookie: '#555759',
		realbookieau: '#555759',
		crossbet: '#111d2b',
		texbet: '#1c5693',
		picnicbet: '#0c4da2',
		pla: '#7fb902',
		zbet: '#050B3C',
		caymanasbet: '#26b47b',
		monmouth: '#000000',
		wish: '#000000',
	},
	bettingEngineLegacyApi: {
		master: 'https://api.mugbookie.com/api/v1',
		benchmark: 'https://api.benchmark.ext.thebetmakers.com/api/v1',
		integration: 'https://api.integration.ext.thebetmakers.com/api/v1',
		bbet: 'https://new-api.bbet.com.au/api/v1',
		betdeluxe: 'https://api.betdeluxe.com.au/api/v1',
		wat: 'https://api.robwaterhouse.com/api/v1',
		realbookie: 'https://api.realbookie.com.au/api/v1',
		realbookieau: 'https://api.realbookie.ext.thebetmakers.com/api/v1',
		crossbet: 'https://api.crossbet.com.au/api/v1',
		texbet: 'https://api.texbet.com.au/api/v1',
		picnicbet: 'https://api.picnicbet.com/api/v1',
		pla: 'https://api.pla.ext.thebetmakers.com/api/v1',
		zbet: 'https://api.zbet.com.au/api/v1',
		caymanasbet: 'https://api.caymanasbet.ext.thebetmakers.com/api/v1',
		monmouth: 'https://api.monmouth.ext.thebetmakers.com/api/v1',
		wish: 'https://api.wishbet.com.au/api/v1',
	},
	aclBrandCode: {
		master: 1,
		benchmark: 1,
		integration: 1,
		bbet: 1,
		betdeluxe: 1,
		wat: 1,
		realbookie: 1,
		realbookieau: 1,
		crossbet: 1,
		texbet: 1,
		picnicbet: 1,
		pla: 1,
		zbet: 1,
		caymanasbet: 1,
		monmouth: 1,
		wish: 1,
	},
	featureFlags: {
		master: {
			promotionEngine: { enabled: true, config: {} },
		},
		benchmark: {
			promotionEngine: { enabled: true, config: {} },
		},
		integration: {
			promotionEngine: { enabled: true, config: {} },
		},
		realbookie: {
			promotionEngine: { enabled: true, config: {} },
		},
		wat: {
			promotionEngine: { enabled: true, config: {} },
		},
		bbet: {
			promotionEngine: { enabled: true, config: {} },
		},
		texbet: {
			promotionEngine: { enabled: true, config: {} },
		},
		crossbet: {
			promotionEngine: { enabled: true, config: {} },
		},
		picnicbet: {
			promotionEngine: { enabled: true, config: {} },
		},
		zbet: {
			promotionEngine: { enabled: true, config: {} },
		},
		wish: {
			promotionEngine: { enabled: true, config: {} },
		},
	},
};

interface featureToggleConfig<T = any> {
	enabled: boolean;
	config: T;
}

export interface useableBrandConfigInterface {
	logo: string;
	displayName: string;
	favicon: string;
	brandManagerEndpoint: string;
	appUrl: string;
	primaryColor: string;
	secondaryColor: string;
	bettingEngineLegacyApi: string;
	aclBrandCode: number;
	featureFlags: {
		promotionEngine?: featureToggleConfig<{}>;
	};
}

export const brandConfigLoader = (
	brand: BrandNameType | typeof FALL_OVER_BRAND = FALL_OVER_BRAND,
): useableBrandConfigInterface => {
	return brand !== FALL_OVER_BRAND && !brandsList.includes(brand)
		? brandConfigLoader()
		: {
				displayName: brandConfig.displayName[brand],
				logo: brandConfig.logo[brand],
				favicon: brandConfig.favicon[brand],
				brandManagerEndpoint: brandConfig.brandManagerEndpoint[brand],
				appUrl: brandConfig.appUrl[brand],
				primaryColor: brandConfig.primaryColors[brand],
				secondaryColor: brandConfig.secondaryColors[brand],
				bettingEngineLegacyApi: brandConfig.bettingEngineLegacyApi[brand],
				aclBrandCode: brandConfig.aclBrandCode[brand],
				featureFlags: brandConfig?.featureFlags?.[brand] || {},
		  };
};

export const configurationLoader = brandConfigLoader;
