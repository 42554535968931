import * as Yup from 'yup';
import slugify from 'slugify';
import { promotion_categories } from '../helpers/promotionEndpoints';

export const contentSlugify = (input: string) =>
	slugify(input.trim(), {
		lower: true,
		replacement: '-',
		strict: true,
	});

export const contentValidation = (slugsNotAllowed: string[]) =>
	Yup.object().shape({
		title: Yup.string().required().min(1),
		slug: Yup.string()
			.required()
			.min(1)
			.transform(contentSlugify)
			.notOneOf(slugsNotAllowed.map(contentSlugify), 'Slug already in use'),
		publish_date: Yup.date().required(),
		content: Yup.string().required(),
	});

export const promotionsValidation = Yup.object().shape({
	title: Yup.string().required().min(5),
	expiry: Yup.date().required(),
	route: Yup.string().required(),
	category: Yup.string().required().oneOf(Object.values(promotion_categories)),
	description: Yup.string().required(),
	terms_and_conditions: Yup.string().required(),
});

export const bannersValidation = Yup.object().shape({
	link: Yup.string().url(),
	image_src: Yup.string().when('image_src_mobile', {
		is: (image_src_mobile) => image_src_mobile.length === 0,
		then: Yup.string().url().required(),
		otherwise: Yup.string().url().required(),
	}),
	image_src_mobile: Yup.string().when('image_src', {
		is: (image_src) => image_src.length === 0,
		then: Yup.string().url().required(),
		otherwise: Yup.string().url().required(),
	}),
}
	// @ts-ignore
	[ [ 'image_src', 'image_src_mobile'] ]

);

export const highlightsValidation = Yup.object().shape({
	title: Yup.string().required('Title is a required field'),
	expiryDate: Yup.date().min(new Date(), 'Date must be in the future'),
	iconKey: Yup.string(),
	link: Yup.string().required('Link is a required field'),
});

export const quickLinksValidation = Yup.object().shape({
	title: Yup.string().required('Title is a required field'),
	expiry: Yup.date().min(new Date(), 'Date must be in the future').nullable(),
	icon: Yup.string().required(),
	route: Yup.string().required('Link is a required field'),
	location: Yup.array()
		.min(1, 'At least one location must be selected')
		.required(),
});
