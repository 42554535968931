import React from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import alertConfirm from 'react-alert-confirm';
import 'react-alert-confirm/dist/index.css';

import { promotionsValidation } from '../../common/validations';
import { updatePromotion, createPromotion } from '../../store/promotions';
import { promotionsFormValues as formValues } from '../../store/promotions';
import { promotion_categories } from '../../helpers/promotionEndpoints';

import MarkdownEditor from '../shared/MarkdownEditor';
import { NavGroup, StyledButton } from '../shared/Navs';
import Card from '../shared/Card';
import UserImageUpload from '../shared/UserImageUpload';
import {
	Holder,
	LabelWithError,
	Input,
	Select,
	SplitView,
	LeftColumn,
	RightColumn,
	Divider,
} from '../shared/FormElements';

import PromotionsPreview from './PromotionsPreview';
import PageHeader from '../shared/PageHeader';
import { FiTrash, FiX, FiSave } from 'react-icons/fi';
import { useBrandConfig } from '../../context/brandConfig/brandConfigContext';

interface FormProps {
	toggleModal: any;
	selectedPromotion: string;
	initialValues: formValues;
	formTitle: string;
	buttonLabel: string;
	handleUpdate: (Object) => void;
	handleCreate: (Object) => void;
	handleDelete: (Object) => void;
}

const PromotionsForm = ({
	toggleModal,
	handleDelete,
	initialValues,
	formTitle,
	buttonLabel,
	handleUpdate,
	handleCreate,
}: FormProps) => {
	const { brandConfig } = useBrandConfig();
	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: true,
		validateOnBlur: true,
		validationSchema: promotionsValidation,
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			values.id !== ''
				? await handleUpdate(values)
				: await handleCreate(values);
			setSubmitting(false);
			toggleModal(false);
			resetForm();
		},
	});

	const handleClose = (e) => {
		if (formik.dirty) {
			alertConfirm({
				title: 'Unsaved content',
				content: 'Leave page? Unpublished changes will be lost',
				okText: 'Leave without saving',
				cancelText: 'Go back to form',
				onOk: () => {
					toggleModal(false);
				},
				onCancel: () => {},
			});
		} else {
			toggleModal(false);
		}
	};

	const routePreview = `link preview: ${brandConfig.appUrl}/${formik.values.route}`;
	const isMobile = window.innerWidth <= 760;
	const isSmallMobile = window.innerWidth <= 500;

	return (
		<form onSubmit={formik.handleSubmit}>
			<Card isModal>
				<PageHeader title={formTitle}>
					<NavGroup>
						<StyledButton
							isactive={true}
							onClick={() => formik.handleSubmit}
							style={{ marginLeft: 0 }}
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{!isSmallMobile && buttonLabel}{' '}
							<FiSave className="icon-only-mobile" />
						</StyledButton>
						{formik.values.id && (
							<StyledButton
								isactive={false}
								onClick={() => handleDelete(formik.values.id)}
								disabled={formik.isSubmitting || !formik.isValid}
							>
								{!isSmallMobile && 'Delete'}{' '}
								<FiTrash className="icon-only-mobile" />
							</StyledButton>
						)}
						<StyledButton onClick={handleClose}>
							{!isSmallMobile && 'Close'} <FiX className="icon-only-mobile" />
						</StyledButton>
					</NavGroup>
				</PageHeader>
				<Divider />

				<SplitView disabled={isMobile}>
					<LeftColumn disabled={isMobile}>
						<div>
							<Holder width="100%">
								<LabelWithError
									htmlFor="title"
									title="Title"
									error={formik.errors.title}
								>
									<Input
										id="title"
										value={formik.values.title}
										onChange={formik.handleChange}
										isError={!!formik.errors.title}
									/>
								</LabelWithError>
							</Holder>
							<Holder width="100%">
								<LabelWithError
									htmlFor="category"
									title="Category"
									error={formik.errors.category}
								>
									<Select
										name="category"
										value={formik.values.category}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										isError={!!formik.errors.category}
										style={{ display: 'block' }}
									>
										<option value="" label="Select a category" />
										{Object.values(promotion_categories).map((category) => (
											<option
												key={category}
												value={category}
												label={[
													category.slice(0, 1).toUpperCase(),
													category.slice(1),
												].join('')}
											/>
										))}
									</Select>
								</LabelWithError>
							</Holder>
							<Holder width="100%">
								<LabelWithError
									htmlFor="route"
									title="Button Link"
									error={formik.errors.route}
									hint={routePreview}
								>
									<Input
										id="route"
										value={formik.values.route}
										onChange={formik.handleChange}
										isError={!!formik.errors.route}
									/>
								</LabelWithError>
							</Holder>
							<Holder width="100%">
								<LabelWithError
									htmlFor="expiry"
									title="Expiry"
									error={formik.errors.expiry}
								>
									<Input
										id="expiry"
										type="datetime-local"
										step="1"
										placeholder="YYYY-MM-DD HH:MM:SS"
										value={formik.values.expiry ?? ''}
										onChange={formik.handleChange}
										isError={!!formik.errors.expiry}
									/>
								</LabelWithError>
							</Holder>
							<Holder width="100%">
								<LabelWithError
									htmlFor="image"
									title="Image"
									error={formik.errors.image}
								>
									<UserImageUpload
										folder="promotion"
										selectedImage={formik.values.image}
										setSelectedImage={(ImgSrc) =>
											formik.setFieldValue('image', ImgSrc, true)
										}
									/>
								</LabelWithError>
							</Holder>
							<Holder width="100%">
								<LabelWithError
									htmlFor="description"
									title="Promotion Description"
									error={formik.errors.description}
								>
									<MarkdownEditor
										value={formik.values.description ?? ''}
										handleChange={({ html, text }) => {
											formik.handleChange({
												target: { id: 'description', value: text },
											} as React.ChangeEvent<HTMLInputElement>);
										}}
									/>
								</LabelWithError>
							</Holder>
							<Holder width="100%">
								<LabelWithError
									htmlFor="terms_and_conditions"
									title="Terms And Conditions"
									error={formik.errors.terms_and_conditions}
								>
									<MarkdownEditor
										value={formik.values.terms_and_conditions ?? ''}
										handleChange={({ html, text }) => {
											formik.handleChange({
												target: { id: 'terms_and_conditions', value: text },
											} as React.ChangeEvent<HTMLInputElement>);
										}}
									/>
								</LabelWithError>
							</Holder>
						</div>
					</LeftColumn>

					<RightColumn disabled={isMobile}>
						<PromotionsPreview promotions={formik.values} />
					</RightColumn>
				</SplitView>
			</Card>
		</form>
	);
};

const promotionsToInitialformValue = (
	promotions: formValues[],
	id: string,
): formValues => {
	const defaultValues = {
		id: '',
		title: '',
		image: '',
		category: 'racing',
		route: '',
		expiry: '',
		description: '',
		terms_and_conditions: '',
	};

	return (
		(id !== null && promotions.find((promotion) => promotion.id === id)) ||
		defaultValues
	);
};

const mapStateToProps = (state) => ({
	selectedPromotion: state.promotions.selectedPromotion,
	formTitle: state.promotions.selectedPromotion?.toString()
		? 'Edit Promotion'
		: 'New Promotion',
	buttonLabel: state.promotions.selectedPromotion?.toString()
		? 'Update'
		: 'Publish',
	initialValues: promotionsToInitialformValue(
		state.promotions.data,
		state.promotions.selectedPromotion?.toString(),
	),
});

const mapDispatchToProps = (dispatch) => {
	return {
		handleCreate: (item) => dispatch(createPromotion(item)),
		handleUpdate: (item) => dispatch(updatePromotion(item)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionsForm);
