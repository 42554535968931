import React from 'react';
import { MdFirstPage, MdLastPage } from 'react-icons/md';
import styled, { css } from '../../common/styled';
import { partialApply } from '../../helpers/pureFunc';

const boolToNumber = (input: boolean) => (input ? 1 : 0);

const logicalXor = (inputA: boolean, inputB: boolean) =>
	(boolToNumber(inputA) ^ boolToNumber(inputB)) === 1;

const StyledPagination = styled.div`
	padding: 10px;
	display: flex;
	width: auto;
	max-width: 40em;
	margin: ${({ theme }) => theme.spacings.comfortable}px;
`;

const StyledPageTab = styled.div<{
	current: number;
	position: number;
}>`
	display: flex;
	${({ current, position }) => {
		const lookup = {
			left: css`
				clip-path: polygon(
					15% 0,
					0% 50%,
					15% 100%,
					100% 100%,
					85% 50%,
					100% 0%
				);
				:hover {
					clip-path: polygon(
						20% 0,
						0% 50%,
						20% 100%,
						100% 100%,
						80% 50%,
						100% 0%
					);
				}
			`,
			right: css`
				clip-path: polygon(0% 0%, 15% 50%, 0% 100%, 85% 100%, 100% 50%, 85% 0%);
				:hover {
					clip-path: polygon(
						0% 0%,
						20% 50%,
						0% 100%,
						80% 100%,
						100% 50%,
						80% 0%
					);
				}
			`,
			none: css`
				clip-path: polygon(
					20% 0%,
					0% 50%,
					20% 100%,
					80% 100%,
					100% 50%,
					80% 0%
				);
				filter: invert();
				transform: scale(1.25);
			`,
		};
		if (position === current) return lookup['none'];

		if (position < current) return lookup['left'];
		else if (position > current) return lookup['right'];
	}};
	:hover {
		filter: invert();
		transform: scale(1.25);
	}
	transition: all 0.5s ease;

	justify-content: center;
	align-items: baseline;

	background: ${({ theme }) => theme.ui.color_3};
	padding: ${({ theme }) => theme.spacings.compact}px;

	cursor: pointer;
	height: 100%;
`;

const ShadowWrap = styled.div`
	flex: 1 0 25px;
	max-width: 2em;
	height: 1em;

	user-select: none;
	padding: 4px;
`;

interface PaginationProps {
	min: number;
	EitherSide: number;
	max: number;
	current: number;
	pageRequest: (input: number) => void;
	preFetchPage?: ({ pageNumber: number }) => void;
}

const Pagination = ({
	min,
	EitherSide: _EitherSide,
	max,
	current,
	pageRequest,
	preFetchPage,
}: PaginationProps) => {
	const EitherSide = Math.max(2, _EitherSide);

	const numberOfButtons = EitherSide * 2;

	const idealMin = Math.max(min, current - EitherSide);

	const idealMax = Math.min(max, current + EitherSide);

	const effectMin = Math.max(min, idealMax - numberOfButtons);

	const effectMax = Math.min(max, idealMin + numberOfButtons);

	const pages = [];

	const positionOverride = { [effectMin]: min, [effectMax]: max };

	for (let i = effectMin; i <= effectMax; i++) {
		const atMin = effectMin === i;

		const atMax = effectMax === i;

		let content;

		if (logicalXor(atMin, atMax)) {
			// atMin or AtMax but not at both
			content = atMin ? <MdFirstPage /> : <MdLastPage />;
		} else content = i;

		pages.push(
			<ShadowWrap key={i}>
				<StyledPageTab
					position={positionOverride[i] ?? i}
					current={current}
					onClick={partialApply(pageRequest, positionOverride[i] ?? i)}
					onMouseEnter={
						preFetchPage ? partialApply(preFetchPage, { pageNumber: i }) : null
					}
					onFocus={
						preFetchPage ? partialApply(preFetchPage, { pageNumber: i }) : null
					}
				>
					{content}
				</StyledPageTab>
			</ShadowWrap>,
		);
	}

	return <StyledPagination>{pages}</StyledPagination>;
};

export default Pagination;
