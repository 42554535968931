import React from 'react';
import moment from 'moment';
import { FiEdit, FiEye } from 'react-icons/fi';
import { ToastsStore } from 'react-toasts';

import styled from '../../common/styled';
import PromotionEngineAPI from '../../helpers/promotionEngine/promotionEngineAPI';
import { promotionItemInterface } from '../../helpers/promotionEngine/promotionEngineInterfaces';
import { partialApply } from '../../helpers/pureFunc';
import { StyledButton } from '../shared/Navs';
import ToggleSwitch from '../shared/ToggleSwitch';
import PromotionEngineManageActionRow from './PromotionEngineManageActionButtons';

const StyledPromotionEngineButton = styled(StyledButton)`
	& > svg {
		margin-left: 0px;
	}
`;

const StyledDateLine = styled.div`
	flex: 1 0 auto;
	display: flex;
	justify-content: space-between;
	& > * {
		white-space: nowrap;
	}
`;

const StyledDescriptionField = styled.td`
	position: relative;
	&::after {
		content: '';
		display: inline-block;
		width: 15em;
	}

	& > div {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		padding: ${({ theme }) => theme.spacings.tight}px;

		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	& > div > span {
		overflow: hidden;
		text-align: center;
		word-wrap: none;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

interface PromotionEngineManageRowProps {
	children: {
		promotionItem: promotionItemInterface;
		redeemCount?: number;
		underMutation: boolean;
		APIHandler: PromotionEngineAPI;
		requestOpenViewClose: (mode: 'view' | 'edit' | 'close' | 'redeem') => void;
	};
	fieldsToHide: Array<keyof promotionItemInterface>;
}

const PromotionEngineManageRow = ({
	children: {
		promotionItem,
		redeemCount,
		underMutation,
		APIHandler,
		requestOpenViewClose,
	},
	fieldsToHide,
}: PromotionEngineManageRowProps) => {
	const formatDate = (input: string) =>
		moment(input, 'yyyy-MM-DD hh:mm:ss').format('yyyy-MM-DD');

	const handleRedeemClick = (e) => {
		e.preventDefault();
		requestOpenViewClose('redeem');
	};

	return (
		<>
			<td
				style={{
					position: 'sticky',
					left: 0,
					background: 'inherit',
				}}
				align="right"
			>
				{promotionItem.id}
			</td>
			<td>{promotionItem.code}</td>
			<td align="right">
				{promotionItem.max_deposit
					? `$${(promotionItem.max_deposit / 100).toFixed(2)}`
					: 'n/a'}
			</td>
			{!fieldsToHide.includes('description') && (
				<StyledDescriptionField>
					<div>
						<span>{promotionItem.description}</span>
					</div>
				</StyledDescriptionField>
			)}
			{!fieldsToHide.includes('start_date') &&
				!fieldsToHide.includes('end_date') && (
					<td align="right">
						<StyledDateLine>
							<span>Start:</span>
							<span> {formatDate(promotionItem.start_date)}</span>
						</StyledDateLine>
						<StyledDateLine>
							<span>Finish: </span>
							<span>
								{promotionItem.end_date
									? formatDate(promotionItem.end_date)
									: 'NULL'}
							</span>
						</StyledDateLine>
					</td>
				)}
			{/* <td>{promotionItem.promotion_type_id}</td> */}
			<td align="right">
				{redeemCount ? (
					<a href="/#" onClick={handleRedeemClick}>
						{redeemCount}
					</a>
				) : (
					'n/a'
				)}
			</td>
			<td align="center">
				<ToggleSwitch
					checked={!!promotionItem.enabled}
					id={'disabled_' + promotionItem.id}
					title="disabled"
					onChange={(e) => {
						const newValue = e.target.checked;
						APIHandler.editPromotion({
							...promotionItem,
							enabled: newValue,
						}).then((result) => {
							const action = newValue ? 'Enable' : 'Disable';
							const failed = result instanceof Error;
							const message = `${
								failed ? 'Failed to' : 'Successfully '
							} ${action}${failed ? 'd' : ''} Item`;
							ToastsStore[failed ? 'error' : 'success'](message);

							APIHandler.executeFetchPromotions();
						});
					}}
				/>
			</td>
			<td align="center" color="white">
				<PromotionEngineManageActionRow>
					<StyledPromotionEngineButton
						disabled={false}
						onClick={partialApply(requestOpenViewClose, 'view')}
					>
						<FiEye />
					</StyledPromotionEngineButton>
					<StyledPromotionEngineButton
						disabled={false}
						onClick={partialApply(requestOpenViewClose, 'edit')}
					>
						<FiEdit />
					</StyledPromotionEngineButton>
				</PromotionEngineManageActionRow>
			</td>
		</>
	);
};

export default PromotionEngineManageRow;
