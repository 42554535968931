interface footerItemConfig {
	enabled: boolean;
	data?: object;
}

export enum TextColorThemeCodes {
	'primary' = 'primary',
	'secondary' = 'secondary',
	'default' = 'default',
	'light' = 'light',
	'dark' = 'dark',
	'alternate' = 'alternate',
	'alternate-light' = 'alternate-light',
	'success' = 'success',
	'danger' = 'danger',
	'warning' = 'warning',
	'info' = 'info',
	'neutral' = 'neutral',
}

export interface termsWarningInterface extends footerItemConfig {
	data?: {
		title: string;
		body: string;
	};
}

export interface mobileAppsInterface extends footerItemConfig {
	data?: {
		Items: {
			[k in 'ios' | 'android']?: { href: string };
		};
	};
}

export enum socialCodes {
	facebook = 'facebook',
	twitter = 'twitter',
	linkedin = 'linkedin',
	whatsapp = 'whatsapp',
	instagram = 'instagram',
	youtube = 'youtube',
	android = 'android',
	apple = 'apple',
	phone = 'phone',
}
export interface socialLinksInterface extends footerItemConfig {
	data?: {
		Items: { code: socialCodes; href: string }[];
	};
}

export enum paymentMethodsCodes {
	'master' = 'master',
	'visa' = 'visa',
	'bpay' = 'bpay',
	'nab' = 'nab',
	'poli' = 'poli',
	'direct' = 'direct',
	'cwa' = 'cwa',
}
export interface paymentMethodsInterface extends footerItemConfig {
	data: {
		Items: paymentMethodsCodes[];
	};
}

export enum approvedPartnersCodes {
	nrl = 'nrl',
	afl = 'afl',
	ffa = 'ffa',
	tennis = 'tennis',
	racingSA = 'racingSA',
	racingNSW = 'racingNSW',
	theraces = 'theraces',
	racingQLD = 'racingQLD',
	racingWA = 'racingWA',
	racingTaz = 'racingTaz',
	thoroughbredpark = 'thoroughbredpark',
}
export interface approvedPartnersInterface extends footerItemConfig {
	data: {
		Items: approvedPartnersCodes[];
	};
}

export interface footerGroupChildItem {
	title: string;
	href?: string;
	slug?: string;
}
export interface footerGroupItem {
	groupName: string;
	Items: footerGroupChildItem[];
}
export interface footerContentInterface extends footerItemConfig {
	data: {
		Items: footerGroupItem[];
	};
}

export interface footerConfigInterface {
	compactLayout: boolean;
	textColorTheme: TextColorThemeCodes;
	Items: {
		ApprovedPartners?: approvedPartnersInterface;
		PaymentMethods?: paymentMethodsInterface;
		Social?: socialLinksInterface;
		TermsWarning?: termsWarningInterface;
		ResponsibleGambling: termsWarningInterface;
		MobileApps?: mobileAppsInterface;
		Content?: footerContentInterface;
	};
}
