import React from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { partialApply } from '../../../helpers/pureFunc';
import PromotionEngineEndpoints from '../../../helpers/promotionEngine/PromotionEngineEndpoints';
import {
	promotionsCreateFailResponse,
	promotionsCreateResponse,
} from '../../../helpers/promotionEngine/rawEndpointFunc/promotionCreate';
import {
	promotionsUpdateFailResponse,
	promotionsUpdateResponse,
} from '../../../helpers/promotionEngine/rawEndpointFunc/promotionUpdate';
import {
	promotionItemInterface,
	promotionTriggerTypes,
} from '../../../helpers/promotionEngine/promotionEngineInterfaces';

import { DepositFormLayout } from '../DepositMatchFormComponents';
import DepositMatchForm from './DepositMatchForm';
import PromotionEngineRedeemedAreaDialog from '../PromotionEngineRedeemedAreaDialog';

export interface PromotionViewEditProps {
	item: promotionItemInterface;
	setViewEditCloseDelete: (input: 'view' | 'edit' | 'close' | 'delete') => void;
	saveChanges: (
		input: promotionItemInterface,
	) => Promise<
		| AxiosResponse<promotionsCreateResponse | promotionsUpdateResponse>
		| AxiosError<
				promotionsCreateFailResponse | promotionsUpdateFailResponse | any
		  >
	>;
	mode: 'view' | 'edit' | 'new' | 'redeem';
	validate: (
		promotionItemInterface,
	) => Promise<{ [K in keyof promotionItemInterface]?: string }>;
	cachedPromotionEndpoints: PromotionEngineEndpoints;
}

type modalContentElementType = (
	props: PromotionViewEditProps,
) => React.ReactElement;

const FallbackModalContent: modalContentElementType = ({
	setViewEditCloseDelete,
}) => (
	<DepositFormLayout
		actionClose={partialApply(setViewEditCloseDelete, 'close')}
		mode="unsupported"
		actionDelete={partialApply(setViewEditCloseDelete, 'delete')}
	>
		<div>Sorry I Don't know how to handle this type of Promotion</div>
	</DepositFormLayout>
);

export const PromotionTypeComponentLookup: {
	[K in promotionTriggerTypes]?: {
		[K in 'view' | 'edit' | 'new' | 'redeem']?: modalContentElementType;
	};
} = {
	[promotionTriggerTypes.DEPOSIT]: {
		edit: DepositMatchForm,
		view: DepositMatchForm,
		new: DepositMatchForm,
	},
	UNKNOWN: {
		new: DepositMatchForm,
	},
};

export const getModalContentElement = (
	triggerType: number,
	mode,
): modalContentElementType => {
	if (mode === 'redeem') return PromotionEngineRedeemedAreaDialog;
	return (
		PromotionTypeComponentLookup?.[triggerType || 'UNKNOWN']?.[mode] ||
		FallbackModalContent
	);
};
