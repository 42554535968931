import axios from 'axios';

class QuickLinkEndpoints {
	constructor(url) {
		this.url = url;
	}

	useAuthCode(code) {
		this.authCode = code;
	}

	authHeader() {
		return { Authorization: 'Bearer ' + this.authCode };
	}

	async get(authStatus) {
		const builtURL = `${this.url}/all`;
		try {
			const response = await axios.get(builtURL, {
				withCredentials: false,
				params: {
					authentication_status: authStatus,
				},
			});
			const Items =
				response.data.Items.length > 0 ? response.data.Items : 'No items';
			return Items;
		} catch (e) {
			console.log('There was a problem fetching quicklinks', e);
			return [];
		}
	}

	async fetchAllQuickLinks(authStatus) {
		return await this.get(authStatus);
	}

	async createQuickLink(quickLinkItem) {
		const builtURL = `${this.url}/create`;
		const bodyJSON = JSON.stringify({ Item: quickLinkItem });

		return await axios.post(builtURL, bodyJSON, {
			headers: { 'content-type': 'application/json', ...this.authHeader() },
		});
	}

	async deleteQuickLink(id) {
		const builtURL = `${this.url}/${id}/delete`;

		return await axios.post(
			builtURL,
			{},
			{ headers: { ...this.authHeader() } },
		);
	}

	async updateQuickLink(quickLinkItem) {
		const id = quickLinkItem.id;
		const builtURL = `${this.url}/${id}/update`;
		const bodyJSON = JSON.stringify({ Item: quickLinkItem });

		return await axios.post(builtURL, bodyJSON, {
			headers: { 'content-type': 'application/json', ...this.authHeader() },
		});
	}
}

export default QuickLinkEndpoints;
