import React from 'react';

import { LinkButton } from './Navs';

interface props {
	isAuth: boolean;
	isMobile?: boolean;
}
const LoginButton = ({ isAuth, isMobile = false }: props) => (
	<LinkButton to={!isAuth ? '/login' : '/logout'} ismobile={isMobile}>
		{!isAuth ? 'Login' : 'Sign Out'}
	</LinkButton>
);

export default LoginButton;
