import React from 'react';
import { FiEdit } from 'react-icons/fi';

import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	ThOptional,
	Td,
	TdOptional,
	TableButton,
	Flex,
} from '../shared/Table';
import CountdownTimer from '../shared/CountdownTimer';
import Pill from '../shared/Pill';
import Anchor from '../shared/Anchor';
import { useBrandConfig } from '../../context/brandConfig/brandConfigContext';

const ContentTable = ({
	promotions,
	handleSelectedKey,
	handlePromotionDelete,
	toggleModal,
}) => {
	const { brandConfig } = useBrandConfig();
	const promotionRoute = (route) => `${brandConfig.appUrl}/${route}`;
	const isMobile = window.innerWidth <= 600;
	return (
		<Table>
			<Thead>
				<tr>
					<Th textAlign="left">Title</Th>
					<Th>Category</Th>
					<ThOptional>Button Link</ThOptional>
					<ThOptional>Expiry</ThOptional>
					<Th>Actions</Th>
				</tr>
			</Thead>
			<Tbody>
				{promotions.length <= 0 && (
					<Tr>
						<Td>No active promotions</Td>
						<Td></Td>
						<TdOptional></TdOptional>
						<TdOptional></TdOptional>
						<Td></Td>
					</Tr>
				)}
				{promotions?.map((promotion) => (
					<Tr key={promotion.id}>
						<Td>{promotion.title}</Td>
						<Td>
							<Pill type={promotion.category} />
						</Td>
						<TdOptional>
							<Anchor
								href={promotionRoute(promotion.route)}
								target="_blank"
								rel="noopener noreferrer"
							>
								{promotionRoute(promotion.route)}
							</Anchor>
						</TdOptional>
						<TdOptional width="150px">
							<CountdownTimer date={promotion.expiry} />
						</TdOptional>
						<Td>
							<Flex>
								<TableButton
									onClick={() => {
										toggleModal(true);
										handleSelectedKey(promotion.id);
									}}
								>
									{!isMobile && 'Edit'} <FiEdit />
								</TableButton>
							</Flex>
						</Td>
					</Tr>
				))}
			</Tbody>
		</Table>
	);
};

export default ContentTable;
