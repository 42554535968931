import React, { useState } from 'react';
import styled from '../../common/styled';

import { Divider } from '../shared/FormElements';
import { StyledButton } from '../shared/Navs';

import BannerForm from './BannerForm';
import NoBanners from '../shared/NoBanners';
import BannerFilter from './BannerFilter';

const TitleContainer = styled('div')`
	display: flex;
	justify-content: space-between;
`;

const BannerEdit = ({
	banners,
	formKey,
	handleToggle,
	handleDelete,
	handleSave,
	handleTabSelect,
	sortByPosition,
	setDirty,
	title,
	handleBannerEdit,
	isLoading,
}) => {
	const [filterStatus, setFilterStatus] = useState(null);

	const handleSelection = (selection) => {
		if (!filterStatus || filterStatus !== selection) {
			setFilterStatus(selection);
		} else if (filterStatus === selection) {
			setFilterStatus(null);
		}
	};

	const filterByAuth = () => {
		if (!filterStatus) {
			return banners[formKey];
		} else if (filterStatus === 'logged-in') {
			return banners[formKey].filter(
				(b) =>
					b.authentication_status === 'signed_in' ||
					b.authentication_status === 'any',
			);
		} else if (filterStatus === 'guests') {
			return banners[formKey].filter(
				(b) =>
					b.authentication_status === 'signed_out' ||
					b.authentication_status === 'any',
			);
		} else {
			return banners[formKey];
		}
	};

	const bannersToShow = filterByAuth();

	return (
		<>
			<TitleContainer>
				<h2>Edit {title} Banners</h2>
				<StyledButton onClick={() => handleToggle()}>Sort</StyledButton>
			</TitleContainer>
			<Divider />

			{!isLoading && (
				<>
					<BannerFilter
						handleSelection={handleSelection}
						currentFilter={filterStatus}
					/>

					{bannersToShow === 'No items' || bannersToShow.length < 1 ? (
						<NoBanners handleTabSelect={handleTabSelect} />
					) : (
						sortByPosition(bannersToShow).map((banner, i) => (
							<BannerForm
								banner={banner}
								key={i}
								formKey={formKey}
								handleDelete={handleDelete}
								handleSave={handleSave}
								setDirty={setDirty}
								handleBannerEdit={handleBannerEdit}
							/>
						))
					)}
				</>
			)}
		</>
	);
};

export default BannerEdit;
