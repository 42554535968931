import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Loading from 'react-loading-bar';
import 'react-loading-bar/dist/index.css';
import { ToastsStore } from 'react-toasts';
import alertConfirm from 'react-alert-confirm';
import 'react-alert-confirm/dist/index.css';

import {
	updateBanner,
	updateBanners,
	createBanner,
	deleteBanner,
	fetchAllBanners,
} from '../store/banners';

import Card from '../components/shared/Card';
import BannerNav from '../components/banners/BannerNav';
import BannerSort from '../components/banners/BannerSort';
import BannerEdit from '../components/banners/BannerEdit';
import BannerCreate from '../components/banners/BannerCreate';
import PageHeader from '../components/shared/PageHeader';
import { NavGroup, StyledButton } from '../components/shared/Navs';
import { ModalStyle } from '../common/modalStyle';
import { FiFile, FiHelpCircle } from 'react-icons/fi';
import BannerDesignInfo from '../components/banners/BannerDesignInfo';
import { banner_types } from '../helpers/bannerEndpoints';
import { staticTheme } from '../common/theme';
import { useBrandConfig } from '../context/brandConfig/brandConfigContext';

type context_type = `${banner_types}` | 'new';

function Banners({
	homepageBanners,
	racingBanners,
	raceBanners,
	sportsHomeBanners,
	eventBanners,
	updateSingleBanner,
	updateMultiBanner,
	createNewBanner,
	deleteSingleBanner,
	fetchByBannerType,
}) {
	const banners = {
		homepage: homepageBanners,
		racing: racingBanners,
		race: raceBanners,
		sports: sportsHomeBanners,
		event: eventBanners,
	};
	const [bannerContext, setBannerContext] = useState<context_type>('homepage');
	const [isEdit, setIsEdit] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [newContext, setNewContext] = useState<context_type>('homepage');
	const [isModalOpen, setModalState] = useState(false);
	const [isDesignInfoOpen, setDesignInfoState] = useState(false);
	const [hasDirtyForm, setHasDirtyForm] = useState(false);
	const [bannerToEdit, setBannerToEdit] = useState(null);

	const closeModal = () => {
		setModalState(false);
		setBannerContext(newContext);
		setBannerToEdit(null);
	};

	const closeDesignInfo = () => {
		setDesignInfoState(false);
	};

	const handleBannerEdit = (b) => {
		setBannerToEdit(b);
		setBannerContext('new');
		setModalState(true);
	};

	const setLoadingFinished = () => setIsLoading(false);

	const brandConfig = useBrandConfig();

	useEffect(() => {
		if (bannerContext !== 'new') {
			const finishLoading = () => setIsLoading(false);
			setIsLoading(true);
			fetchByBannerType(bannerContext)
				?.catch?.(finishLoading)
				?.then(finishLoading);
		}
	}, [bannerContext, fetchByBannerType, brandConfig]);

	const buildMutableBannersArray = (
		immutableBanners,
		remapPosition: boolean = false,
	) => {
		let newBanners = immutableBanners.map((item, i) => {
			return {
				authentication_status: item.authentication_status,
				banner_type: item.banner_type,
				id: item.id,
				image_src: item.image_src,
				image_src_mobile: item.image_src_mobile || '',
				image_width: item.image_width || null,
				link: item.link,
				position: remapPosition ? i + 1 : item.position,
				visible: item.visible,
				context_id: item.context_id,
			};
		});
		return newBanners;
	};

	const sortByPosition = (bannersArray) => {
		let newBanners = buildMutableBannersArray(bannersArray);
		newBanners = newBanners.sort((a, b) => {
			if (a.position > b.position) {
				return 1;
			} else if (a.position < b.position) {
				return -1;
			} else {
				return 0;
			}
		});
		return newBanners;
	};

	const handleTabSelect = (e, name) => {
		e.preventDefault();
		if (name === 'new') {
			setNewContext(bannerContext);
			setModalState(true);
		}
		if (hasDirtyForm) {
			alertConfirm({
				title: 'Unsaved content',
				content: 'Leave page? Unpublished changes will be lost',
				okText: 'Leave without saving',
				cancelText: 'Go back to form',
				onOk: () => {
					setBannerContext(name);
				},
				onCancel: () => {},
			});
		} else {
			setBannerContext(name);
		}
	};

	// Are any forms in currently viewed array in a dirty state?
	let dirties = {};
	const setDirty = (id, dirty) => {
		if (dirty) {
			dirties[id] = true;
		} else if (!dirty && dirties[id]) {
			dirties[id] = false;
		}
		setHasDirtyForm(Object.values(dirties).includes(true));
	};

	const handleToggle = () => {
		setIsEdit(!isEdit);
	};

	const handleSave = async (formKey, formData) => {
		setIsLoading(true);
		const changedBanner = { ...formData, banner_type: formKey };
		setIsLoading(true);
		await updateSingleBanner(changedBanner);
		setIsLoading(false);
	};

	const handleCreate = async (newBanner, formKey) => {
		setIsLoading(true);
		const createdBanner = {
			...newBanner,
			position:
				banners[formKey] === 'No items' ? 1 : banners[formKey].length + 1,
			visible: true,
			image_width: newBanner.image_width || 0,
		};
		await createNewBanner(createdBanner)
			.catch(() => {
				ToastsStore.error('Failed to create Banner');
				setLoadingFinished();
			})
			.then(setLoadingFinished);
	};

	const handleDelete = (banner) => {
		const sure = window.confirm(
			'Please confirm. This banner will be immediately removed and unpublished.',
		);
		if (sure) {
			setIsLoading(true);
			deleteSingleBanner(banner)
				.catch(setLoadingFinished)
				.then(setLoadingFinished);
		}
	};

	const handleSortPublish = async (sortedBanners) => {
		setIsLoading(true);
		try {
			// would be good to deduplicate these requests to only update banners with changes
			const bulkUpdates = sortedBanners.map((banner) =>
				updateMultiBanner(banner, false),
			);
			await Promise.all(bulkUpdates);

			ToastsStore.success('Banner positions updated', 2000);
		} catch (error) {
			console.log('failed to bulk update banner positions', error);
			ToastsStore.error('Not Every Promotion Item updated successfully');
		}

		await fetchByBannerType(bannerContext)
			.catch(setLoadingFinished)
			.then(setLoadingFinished);
	};

	const getPageTitle = (formKey: banner_types) => {
		const titleLookup: Record<banner_types, string> = {
			homepage: 'Homepage',
			racing: 'Racing Homepage',
			race: 'Race & Meeting',
			sports: 'Sports Homepage',
			event: 'Sports & Events',
		};

		return titleLookup[formKey] || titleLookup['homepage'];
	};

	const BannerSortComponent = (formKey) => (
		<BannerSort
			formKey={formKey}
			banners={banners}
			handleToggle={handleToggle}
			handleSortPublish={handleSortPublish}
			handleTabSelect={handleTabSelect}
			buildMutableBannersArray={buildMutableBannersArray}
			sortByPosition={sortByPosition}
			title={getPageTitle(formKey)}
			isLoading={isLoading}
		/>
	);

	const renderSort = () =>
		['racing', 'race', 'sports', 'event', 'homepage'].includes(bannerContext)
			? BannerSortComponent(bannerContext)
			: BannerSortComponent('homepage');

	const BannerEditComponent = (formKey) => (
		<BannerEdit
			formKey={formKey}
			banners={banners}
			handleToggle={handleToggle}
			handleDelete={handleDelete}
			handleSave={handleSave}
			handleTabSelect={handleTabSelect}
			sortByPosition={sortByPosition}
			setDirty={setDirty}
			title={getPageTitle(formKey)}
			handleBannerEdit={handleBannerEdit}
			isLoading={isLoading}
		/>
	);

	const renderEdit = () =>
		['racing', 'race', 'sports', 'event', 'homepage'].includes(bannerContext)
			? BannerEditComponent(bannerContext)
			: BannerEditComponent('homepage');

	return (
		<>
			<Card marginBottom>
				<Loading
					show={isLoading}
					color={staticTheme.brand.color_1}
					showSpinner={false}
				/>
				<PageHeader title="Banners">
					<NavGroup>
						<StyledButton
							isactive={false}
							onClick={(e) => {
								setDesignInfoState(true);
							}}
						>
							Help <FiHelpCircle />
						</StyledButton>
						<StyledButton
							isactive={true}
							onClick={(e) => {
								handleTabSelect(e, 'new');
								setModalState(true);
							}}
						>
							New Banner <FiFile />
						</StyledButton>
					</NavGroup>
				</PageHeader>
				<BannerNav
					handleTabSelect={handleTabSelect}
					currentView={bannerContext}
				/>
			</Card>

			{bannerContext === 'new' && (
				<Modal
					isOpen={isModalOpen}
					onRequestClose={closeModal}
					style={ModalStyle}
					shouldCloseOnOverlayClick={false}
				>
					<BannerCreate
						handleCreate={handleCreate}
						closeModal={closeModal}
						context={newContext}
						bannerToEdit={bannerToEdit}
					/>
				</Modal>
			)}

			{bannerContext !== 'new' && (
				<Card>{isEdit ? renderEdit() : renderSort()}</Card>
			)}

			{isDesignInfoOpen && (
				<Modal
					isOpen={isDesignInfoOpen}
					onRequestClose={closeDesignInfo}
					style={ModalStyle}
					shouldCloseOnOverlayClick={false}
				>
					<BannerDesignInfo closeModal={closeDesignInfo} />
				</Modal>
			)}
		</>
	);
}

const mapStateToProps = (state) => ({
	homepageBanners: state.banners.homepage,
	racingBanners: state.banners.racing,
	raceBanners: state.banners.race,
	sportsHomeBanners: state.banners.sports,
	eventBanners: state.banners.event,
});

const mapDispatchToProps = (dispatch) => {
	const fetchByBannerType = (banner_type) =>
		dispatch(fetchAllBanners(banner_type));

	return {
		updateSingleBanner: async (banner, refetch = true) => {
			await dispatch(updateBanner(banner));
			if (refetch) await fetchByBannerType(banner.banner_type);
		},
		updateMultiBanner: async (banner, refetch = true) => {
			await dispatch(updateBanners(banner));
			if (refetch) await fetchByBannerType(banner.banner_type);
		},
		createNewBanner: async (banner, refetch = true) => {
			await dispatch(createBanner(banner));
			if (refetch) await fetchByBannerType(banner.banner_type);
		},
		deleteSingleBanner: async (banner, refetch = true) => {
			await dispatch(deleteBanner(banner));
			if (refetch) await fetchByBannerType(banner.banner_type);
		},
		fetchByBannerType,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Banners);
