// @ts-nocheck

import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled, { css } from '../../common/styled';
import {
	brandConfig as MasterBrandConfig,
	FALL_OVER_BRAND,
} from '../../context/brandConfig/brandConfig';
import { useBrandConfig } from '../../context/brandConfig/brandConfigContext';
import { setBrand } from '../../store/application';

interface brandSelectorProps {
	selectedBrand: string;
	handleBrandSet?: () => {};
}

interface hasSelectedProp {
	isSelected?: boolean;
}

const Option = styled.div<hasSelectedProp>(
	(props) => css`
		display: flex;
		align-items: center;

		cursor: pointer;
		margin-bottom: 4px;
		padding: 4px;
		background-color: ${props.isSelected
			? props.theme.brand.color_2
			: '#f3f3f3'};
		color: ${props.isSelected
			? props.theme.typography.color_1_alternate
			: props.theme.typography.color_1};
		:hover {
			background-color: ${props.isSelected
				? props.theme.brand.color_2
				: '#f8f8f8'};
		}
	`,
);

const DropDown = styled.div<hasSelectedProp>(
	(props) => css`
		position: absolute;
		top: 75px;
		min-width: 200px;
		padding: 8px;
		background-color: #ffffff;
		border-radius: 4px;
		border: 1px solid #dddddd;
		z-index: 9999;
	`,
);

const Selected = styled.div(
	(props) => css`
		display: flex;
		align-items: center;
		position: relative;
		height: 100%;
		width: fit-content;
		padding: 10px;
		border-radius: 4px;
		user-select: none;
		background-color: ${props.theme.brand.color_2};
		border: 3px solid ${props.theme.brand.color_1};
		color: ${props.theme.typography.color_1_alternate};
		cursor: pointer;
		font-weight: bold;
		font-size: 18px;
		:hover {
			transform: scale(0.98);
		}
	`,
);

const FaviconImg = styled.img(
	() => css`
		width: 32px;
		height: 32px;
		border-radius: 4px;
		margin-right: 8px;
	`,
);

const Logo = styled('img')`
	label: Header__Logo;

	height: 40px;
	margin-right: 20px;
`;

const BrandSelector = ({
	selectedBrand,
	handleBrandSet: _handleBrandSet,
}: brandSelectorProps) => {
	const { brandList } = useBrandConfig();

	const handleBrandSet = React.useCallback(_handleBrandSet, []);

	useLayoutEffect(() => {
		const selectedBrandIsValid =
			selectedBrand &&
			selectedBrand !== FALL_OVER_BRAND &&
			brandList.includes(selectedBrand);

		if (!selectedBrandIsValid && brandList.length) handleBrandSet(brandList[0]);
	}, [brandList, selectedBrand, handleBrandSet]);

	const [isDropDownOpen, setDropDown] = useState(false);

	return (
		<>
			<Selected onClick={() => setDropDown(!isDropDownOpen)}>
				<Logo
					src={MasterBrandConfig.logo[selectedBrand]}
					alt={MasterBrandConfig.displayName[selectedBrand]}
				/>
			</Selected>

			{brandList?.length && isDropDownOpen && (
				<DropDown>
					{brandList.map((brand) => (
						<Option
							key={brand}
							value={brand}
							onClick={() => {
								setDropDown(false);
								handleBrandSet(brand);
							}}
							isSelected={brand === selectedBrand}
						>
							<FaviconImg src={MasterBrandConfig.favicon[brand]} alt="logo" />
							<span>{MasterBrandConfig.displayName[brand]}</span>
						</Option>
					))}
				</DropDown>
			)}

			{!brandList?.length && (
				<div>
					<p>
						You don't appear to have any brands authorized for your account.
					</p>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedBrand: state.application.brand,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleBrandSet: (brand) => dispatch(setBrand(brand)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandSelector);
