import React from 'react';
import { Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { FiSave, FiX } from 'react-icons/fi';
import moment from 'moment';

import { quickLinksValidation } from '../../common/validations';
import { partialApply } from '../../helpers/pureFunc';
import { icons } from '../../common/icons';

import {
	LabelWithError,
	Input,
	Select,
	CheckboxLabel,
	CheckboxGroup,
	StyledCheckbox,
} from '../shared/FormElements';
import { NavGroup, StyledButton } from '../shared/Navs';
import PageHeader from '../shared/PageHeader';
import Card from '../shared/Card';

import 'react-datepicker/dist/react-datepicker.css';

const QuickLinksForm = ({
	closeModal,
	initialValues,
	isEditing,
	addQuickLink,
	updateQuickLink,
}) => {
	const locations = [
		{ title: 'Homepage', value: 'homepage-racing' },
		{ title: 'Racing', value: 'racing' },
		{ title: 'Sports', value: 'sports' },
		{ title: 'Left Navigation', value: 'left-nav' },
	];

	const routerKeys = [
		{ title: 'Racing', value: 'racing' },
		{ title: 'Sports', value: 'sports' },
		{ title: 'User', value: 'user' },
	];

	const handleDateSubmit = (setFieldValue, date) => {
		setFieldValue('expiry', date, true);
	};

	const handleOnSubmit = (values) => {
		const expiryDate = !values.expiry
			? null
			: moment(values.expiry).format('YYYY-MM-DD HH:mm');

		const newValues = {
			...values,
			expiry: expiryDate,
			mobileRoute: {
				...values.mobileRoute,
				raceId: values.raceId || null,
				meetingId: values.meetingId || null,
				eventId: values.eventId || null,
				competitionId: values.competitionId || null,
			},
		};

		if (isEditing) {
			updateQuickLink(newValues);
		} else {
			addQuickLink(newValues);
		}
		closeModal();
	};

	return (
		<Card isModal>
			<Formik
				initialValues={initialValues}
				validationSchema={quickLinksValidation}
				validateOnMount={true}
				onSubmit={handleOnSubmit}
			>
				{({
					values,
					setFieldValue,
					handleChange,
					handleBlur,
					handleSubmit,
					errors,
					touched,
					isValid,
					isValidating,
					dirty,
				}) => (
					<>
						<PageHeader
							title={isEditing ? 'Edit QuickLink' : 'Create Quicklink'}
						>
							<NavGroup>
								<StyledButton
									isactive="true"
									type="button"
									onClick={handleSubmit}
									disabled={!isValid || isValidating}
								>
									Save <FiSave />
								</StyledButton>
								<StyledButton onClick={closeModal}>
									Close <FiX />
								</StyledButton>
							</NavGroup>
						</PageHeader>
						<LabelWithError
							htmlFor="title"
							title="Title"
							error={touched.title && errors.title}
						>
							<Input
								name="title"
								value={values.title}
								onChange={handleChange}
								onBlur={handleBlur}
								isError={touched.title && errors.title}
								type="text"
								disabled={values.disabled}
							/>
						</LabelWithError>
						<LabelWithError
							htmlFor="route"
							title="Link to target event/page"
							error={touched.route && errors.route}
						>
							<Input
								name="route"
								value={values.route}
								onChange={handleChange}
								onBlur={handleBlur}
								isError={touched.route && errors.route}
								type="url"
								disabled={values.disabled}
								placeholder={
									'e.g. https://yoursite.com.au/racing/gallops/randwick/race-4-1350539-1234670'
								}
							/>
						</LabelWithError>
						<LabelWithError
							htmlFor="expiry"
							title="Expiry Date"
							error={errors.expiry}
							hint={'Leave blank for no expiry date'}
						>
							<DatePicker
								selected={values.expiry}
								onChange={partialApply(handleDateSubmit, setFieldValue)}
								showTimeSelect
								popperPlacement="top-start"
								timeFormat="HH:mm"
								timeIntervals={15}
								timeCaption="time"
								dateFormat="MMM d, yyyy h:mm aa"
								showPopperArrow={false}
								placeholderText="Select a date and time"
								isClearable
							/>
						</LabelWithError>
						<LabelWithError
							htmlFor="icon"
							title="Icon"
							error={errors.icon}
							hint={'Required'}
						>
							<Select
								name="icon"
								value={values.icon}
								onChange={handleChange}
								onBlur={handleBlur}
								isError={errors.icon}
								type="text"
								disabled={values.disabled}
							>
								<option value="" label="None" />
								{icons.map((icon) => (
									<option
										key={icon.value}
										value={icon.value}
										label={icon.label}
									/>
								))}
							</Select>
						</LabelWithError>
						<LabelWithError
							error={touched.location && errors.location}
							id="checkbox-group"
							htmlFor="location"
						>
							Location
							<CheckboxGroup role="group" aria-labelledby="checkbox-group">
								{locations.map((l) => (
									<CheckboxLabel key={l.value}>
										<StyledCheckbox
											type="checkbox"
											name="location"
											value={l.value}
										/>
										{l.title}
									</CheckboxLabel>
								))}
							</CheckboxGroup>
						</LabelWithError>
						<LabelWithError
							htmlFor="mobileRoute.routerKey"
							title="routerKey"
							error={touched.routerKey && errors.routerKey}
							hint={'Required to show quicklink on mobile app'}
						>
							<Select
								name="mobileRoute.routerKey"
								value={values.mobileRoute.routerKey}
								onChange={handleChange}
								onBlur={handleBlur}
								isError={false}
								type="text"
								disabled={values.disabled}
							>
								<option value="" label="None" />
								{routerKeys.map((routerKey) => {
									return (
										<option
											key={routerKey.value}
											value={routerKey.value}
											label={routerKey.title}
										/>
									);
								})}
							</Select>
						</LabelWithError>
						<LabelWithError
							htmlFor={values.authentication_status}
							title="Display"
							error={
								touched.authentication_status && errors.authentication_status
							}
						>
							<Select
								name="authentication_status"
								className="thin"
								value={values.authentication_status}
								onChange={handleChange}
								onBlur={handleBlur}
								isError={false}
							>
								<option value="signed_in" label="Logged In Users" />
								<option value="signed_out" label="Guests Only" />
								<option value="any" label="All" />
							</Select>
						</LabelWithError>
						{values.mobileRoute.routerKey === 'racing' && (
							<>
								<LabelWithError
									htmlFor="mobileRoute.meetingId"
									title="Meeting ID"
									error={errors.meetingId}
								>
									<Input
										name="mobileRoute.meetingId"
										value={values.mobileRoute.meetingId || ''}
										onChange={handleChange}
										onBlur={handleBlur}
										isError={false}
										type="text"
										disabled={values.disabled}
									/>
								</LabelWithError>
								<LabelWithError
									htmlFor="mobileRoute.raceId"
									title="Race ID"
									error={errors.raceId}
								>
									<Input
										name="mobileRoute.raceId"
										value={values.mobileRoute.raceId || ''}
										onChange={handleChange}
										onBlur={handleBlur}
										isError={false}
										type="text"
										disabled={values.disabled}
									/>
								</LabelWithError>
							</>
						)}
						{values.mobileRoute.routerKey === 'sports' && (
							<>
								<LabelWithError
									htmlFor="mobileRoute.eventId"
									title="Event ID"
									error={errors.eventId}
								>
									<Input
										name="mobileRoute.eventId"
										value={values.mobileRoute.eventId || ''}
										onChange={handleChange}
										onBlur={handleBlur}
										isError={false}
										type="text"
										disabled={values.disabled}
									/>
								</LabelWithError>
								<LabelWithError
									htmlFor="mobileRoute.competitionId"
									title="Competition ID"
									error={errors.competitionId}
								>
									<Input
										name="mobileRoute.competitionId"
										value={values.mobileRoute.competitionId || ''}
										onChange={handleChange}
										onBlur={handleBlur}
										isError={false}
										type="text"
										disabled={values.disabled}
									/>
								</LabelWithError>
							</>
						)}
					</>
				)}
			</Formik>
		</Card>
	);
};

export default QuickLinksForm;
