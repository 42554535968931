import React, { useRef, useState } from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import { ToastsStore } from 'react-toasts';
import Modal from 'react-modal';
import { FaSort } from 'react-icons/fa';

import styled, { css } from '../../common/styled';
import { ModalStyle } from '../../common/modalStyle';
import { partialApply } from '../../helpers/pureFunc';
import { promotionItemInterface } from '../../helpers/promotionEngine/promotionEngineInterfaces';
import { promotionsUpdateResponse } from '../../helpers/promotionEngine/rawEndpointFunc/promotionUpdate';
import { promotionsCreateResponse } from '../../helpers/promotionEngine/rawEndpointFunc/promotionCreate';
import { promotionValidateResponseFail } from '../../helpers/promotionEngine/rawEndpointFunc/promotionValidate';
import { PromotionEngineSortByKeysArray } from '../../helpers/promotionEngine/rawEndpointFunc/promotionsList';
import { LightenDarkenColor } from '../../common/LightenDarkenColor';

import usePromotionEngineAPI from './usePromotionEngineAPI';
import useElementSize from '../../hooks/useElementSize';
import { getModalContentElement } from './PromotionEngineForms';
import PromotionEngineManageRow from './PromotionEngineManageRow';
import { StyledButton } from '../shared/Navs';
import Pagination from './Pagination';
import Card from '../shared/Card';

const ScrollBox = styled.div`
	margin: 0 auto;
	width: min(100%, 90vw);
	height: auto;
	position: relative;
	overflow-x: scroll;
`;

export const ManageTable = styled.table<{ underMutation?: boolean }>`
	padding-top: 0px;
	position: relative;
	th {
		vertical-align: 'top';
	}

	width: 100%;
	table-layout: auto;
	border: 2px solid ${(props) => props.theme.ui.color_black};

	${({ underMutation }) =>
		underMutation
			? css`
					filter: blur(4px) hue-rotate(35deg);
					pointer-events: none;
			  `
			: ''}

	transition: all .3s ease;
	td,
	th {
		padding: ${({ theme }) => theme.spacings.compact}px;
		font-size: 85%;
	}
	box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.35);
`;

const Thead = styled.thead`
	width: 100%;
`;

const TrHead = styled.tr`
	width: 100%;
	position: relative;
`;

const Tr = styled.tr<{ visibility?: string }>`
	width: 100%;
	${({ visibility }) =>
		visibility &&
		css`
			visibility: ${visibility};
		`};
	&:nth-of-type(2n + 1) {
		background: ${({ theme }) => theme.ui.background};
	}
	&:nth-of-type(2n) {
		background: ${({ theme }) => LightenDarkenColor(theme.ui.color_3, 10)};
	}
`;

const Tbody = styled.tbody``;

const TableHeaderField = ({
	children,
	onClick,
	sortIcon,
	raw,
	...RestOfProps
}: React.DetailedHTMLProps<
	React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
	HTMLTableHeaderCellElement
> & {
	fieldName?: string;
	sortIcon?: boolean;
	raw?: boolean;
}) => {
	const StyledTh = styled.th`
		position: sticky;
		top: 0;
		z-index: 2;
		&:first-of-type {
			left: 0;
			z-index: 3;
		}
		color: #fff;
		background-color: ${(props) => props.theme.ui.color_black};
		${(onClick && 'cursor: pointer;') || ''}
		vertical-align: top;
	`;

	const StyledWrapper = styled.div`
		display: flex;
		justify-content: space-between;
	`;

	return (
		<StyledTh onClick={onClick} {...RestOfProps}>
			{!raw ? (
				<StyledWrapper>
					{children}
					{sortIcon && <FaSort />}
				</StyledWrapper>
			) : (
				<>
					{children}
					{sortIcon && <FaSort />}
				</>
			)}
		</StyledTh>
	);
};

const StyledDateHeader = ({
	sortByKey,
	preFetchSortByKey,
}: { [K in 'sortByKey' | 'preFetchSortByKey']: (input: string) => void }) => {
	const StyledWrapper = styled(TableHeaderField)``;

	const TitleWrapper = styled.div`
		margin-bottom: 4px;
	`;

	const DateDiv = styled.div`
		width: 100%;
		& > * {
			cursor: pointer;
			font-weight: 500;
			font-size: 80%;
			width: 100%;
			justify-content: space-between;
		}
		flex: 1 0 auto;
		display: flex;
		justify-content: space-between;
	`;

	return (
		<StyledWrapper raw={true}>
			<TitleWrapper>Dates</TitleWrapper>
			<DateDiv>
				<div
					onClick={partialApply(sortByKey, 'start_date')}
					onMouseEnter={partialApply(preFetchSortByKey, 'start_date')}
				>
					<span>Start</span>
					<FaSort />
				</div>
				<div
					onClick={partialApply(sortByKey, 'end_date')}
					onMouseEnter={partialApply(preFetchSortByKey, 'end_date')}
				>
					<span>Finish</span>
					<FaSort />
				</div>
			</DateDiv>
		</StyledWrapper>
	);
};

interface ModalContentProps {
	ItemOpenDetail: promotionItemInterface | null;
	mode: 'view' | 'edit' | 'new' | 'redeem';
	id: number | null;
	viewEditCloseDeleteById: (
		id: number,
		mode: 'view' | 'edit' | 'close' | 'delete' | 'redeem',
	) => void;
	APIHandler: ReturnType<typeof usePromotionEngineAPI>['APIHandler'];
}
const ModalContent = ({
	ItemOpenDetail,
	mode,
	id,
	viewEditCloseDeleteById,
	APIHandler,
}: ModalContentProps) => {
	const ModalContentElement = getModalContentElement(
		ItemOpenDetail?.promotion_type_id,
		mode,
	);

	const validatePromoItem = async (
		promo: promotionItemInterface,
	): Promise<{ [K in keyof promotionItemInterface]?: string }> => {
		const joinFieldErrors = (input: promotionValidateResponseFail) =>
			Object.entries(input?.errors || {})
				.map(([key, value]) => [key, value.join('/n')])
				.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

		const id = promo?.id || undefined;

		const result = await APIHandler.validationPromotion(promo, id);

		if (result instanceof Error) {
			const isValidationError = (
				input: AxiosError,
			): input is AxiosError<promotionValidateResponseFail> =>
				input?.response?.status === 422;
			if (isValidationError(result))
				return joinFieldErrors(result?.response?.data);

			// no error
			return {};
		}
	};
	const handleSave = async (
		input: Omit<promotionItemInterface, 'id'> & { id?: number },
	): Promise<
		| AxiosError<any>
		| AxiosResponse<promotionsUpdateResponse | promotionsCreateResponse>
	> => {
		const isFullItem = (input): input is promotionItemInterface => !!input.id;

		const results = isFullItem(input)
			? await APIHandler.editPromotion(input)
			: await APIHandler.createPromotion(input);

		if (results instanceof Error) {
			if (results.response.status === 404) {
				ToastsStore.error('Failed To Save, as Item no longer exists', 2000);
				viewEditCloseDeleteById(input?.id, 'close');
			} else {
				ToastsStore.error('Failed To Save Item');
			}
		}
		APIHandler.endpoints.clearCache();
		APIHandler.executeFetchPromotions();

		return results;
	};
	return (
		<ModalContentElement
			item={ItemOpenDetail}
			saveChanges={handleSave}
			validate={validatePromoItem}
			setViewEditCloseDelete={partialApply(viewEditCloseDeleteById, id)}
			mode={mode}
			cachedPromotionEndpoints={APIHandler.endpoints}
		/>
	);
};

interface props {
	usePromotionHandler: ReturnType<typeof usePromotionEngineAPI>;
	searchPhrase: string;
	setSearchPhrase: (input: string) => void;
}

const PromotionEngineManageScreen = ({
	usePromotionHandler,
	searchPhrase,
	setSearchPhrase,
}: props) => {
	const windowRef = useRef(window);

	const { width: windowWidth } = useElementSize<Window>(windowRef);

	const {
		PromotionEngineListPageData,
		underMutation,
		APIHandler,
	} = usePromotionHandler;

	const promotionItems = PromotionEngineListPageData?.data?.data;
	const currentPage = PromotionEngineListPageData?.pageParams?.page;
	const maxPage = PromotionEngineListPageData?.data?.last_page || 1;

	const [ItemOpen, setItemOpen] = useState<{
		id: number | null;
		mode: 'view' | 'edit' | 'new' | 'redeem';
	} | null>(null);
	const ItemOpenDetail: promotionItemInterface | null = ItemOpen?.id
		? promotionItems?.find?.((item) => item.id === ItemOpen.id)
		: null;

	const fieldsToHide: Array<keyof promotionItemInterface> =
		windowWidth >= 750 ? [] : ['start_date', 'end_date', 'description'];

	const createRequestHandler = partialApply(setItemOpen, {
		id: null,
		mode: 'new',
	});

	const sortByKey = (key: PromotionEngineSortByKeysArray[number]['key']) => {
		APIHandler.PromotionSetSortKey(key)
			.setPromotionPageRange(1)
			.executeFetchPromotions();
	};

	const viewEditCloseDeleteById = async (
		id: number,
		mode: 'view' | 'edit' | 'close' | 'delete' | 'redeem',
	) => {
		if (mode === 'close') setItemOpen(null);
		else if (mode === 'delete') {
			if (window.confirm('Are you sure you want to delete this promotion')) {
				setItemOpen(null);
				const result = await APIHandler.deletePromotion(id);
				if (result instanceof Error) ToastsStore.error('Failed To Delete Item');
				else ToastsStore.success('Successfully Deleted Item');
			}
		} else setItemOpen({ id, mode });
	};

	const pageChangeRequest = (input: number) =>
		APIHandler.setPromotionPageRange(input).executeFetchPromotions();

	const pagePreFetchRequest = ({
		pageNumber: _pageNumber,
		sortKeyArray: _sortKeyArray,
		search: _search,
		itemsPerPage: _itemsPerPage,
	}: {
		pageNumber?: number;
		sortKeyArray?: PromotionEngineSortByKeysArray;
		search?: string;
		itemsPerPage?: number;
	}) => {
		const pageNumber = _pageNumber || APIHandler.promotionListPageParams.page;
		const sortKeyArray =
			_sortKeyArray || APIHandler.promotionListPageParams.sortKeys;
		const search = _search || APIHandler.promotionListPageParams.searchTerm;
		const itemsPerPage =
			_itemsPerPage || APIHandler.promotionListPageParams.items;

		return void APIHandler.preFetchPromotions(
			pageNumber,
			itemsPerPage,
			sortKeyArray,
			search,
		);
	};

	const findNextKeyDir = (
		key: PromotionEngineSortByKeysArray[number]['key'],
	): PromotionEngineSortByKeysArray[number]['dir'] => {
		const prevKey = APIHandler.promotionListPageParams.sortKeys?.[0];
		if (prevKey?.key === key) {
			return prevKey?.dir === 'desc' ? 'asc' : 'desc';
		}
		return 'asc';
	};

	const preFetchSortKey = (
		key: PromotionEngineSortByKeysArray[number]['key'],
	) => {
		pagePreFetchRequest({
			pageNumber: 1,
			sortKeyArray: [{ key, dir: findNextKeyDir(key) }],
		});
	};

	return (
		<div>
			<Modal
				isOpen={!!ItemOpen?.id || ItemOpen?.mode === 'new'}
				style={ModalStyle}
				shouldCloseOnOverlayClick={false}
			>
				<Card isModal>
					<ModalContent
						id={ItemOpen?.id}
						mode={ItemOpen?.mode}
						ItemOpenDetail={ItemOpenDetail}
						viewEditCloseDeleteById={viewEditCloseDeleteById}
						APIHandler={APIHandler}
					/>
				</Card>
			</Modal>
			{promotionItems && (
				<ScrollBox>
					<div>
						<ManageTable underMutation={underMutation}>
							<Thead>
								<TrHead>
									<TableHeaderField
										onClick={partialApply(sortByKey, 'id')}
										onMouseEnter={partialApply(preFetchSortKey, 'id')}
										fieldName={'id'}
										align="right"
										sortIcon={true}
									>
										ID
									</TableHeaderField>
									<TableHeaderField
										onClick={partialApply(sortByKey, 'code')}
										onMouseEnter={partialApply(preFetchSortKey, 'code')}
										fieldName="promoCode"
										align="center"
										sortIcon={true}
									>
										Code
									</TableHeaderField>
									<TableHeaderField
										onClick={partialApply(sortByKey, 'max_deposit')}
										onMouseEnter={partialApply(preFetchSortKey, 'max_deposit')}
										fieldName="value"
										align="center"
										sortIcon={true}
									>
										Value
									</TableHeaderField>
									{!fieldsToHide.includes('description') && (
										<TableHeaderField
											onClick={partialApply(sortByKey, 'description')}
											onMouseEnter={partialApply(
												preFetchSortKey,
												'description',
											)}
											fieldName="description"
											align="center"
											sortIcon={true}
										>
											Description
										</TableHeaderField>
									)}
									{!fieldsToHide.includes('start_date') &&
										!fieldsToHide.includes('end_date') && (
											<StyledDateHeader
												sortByKey={sortByKey}
												preFetchSortByKey={preFetchSortKey}
											/>
										)}
									{/* <TableHeaderField
										onClick={partialApply(sortByKey, 'promotion_type_id')}
										onMouseEnter={partialApply(
											preFetchSortKey,
											'promotion_type_id',
										)}
										fieldName="triggerType"
										align="center"
										sortIcon={true}
									>
										Type
									</TableHeaderField> */}
									{!fieldsToHide.includes('redemption_count') && (
										<TableHeaderField
											align="right"
											onClick={partialApply(sortByKey, 'redemption_count')}
											onMouseEnter={partialApply(
												preFetchSortKey,
												'redemption_count',
											)}
											sortIcon={true}
										>
											Redeemed
										</TableHeaderField>
									)}
									<TableHeaderField
										align="center"
										onClick={partialApply(sortByKey, 'enabled')}
										onMouseEnter={partialApply(preFetchSortKey, 'enabled')}
										sortIcon={true}
									>
										Active
									</TableHeaderField>
									<TableHeaderField raw={true} align="center">
										Actions
									</TableHeaderField>
								</TrHead>
							</Thead>
							<Tbody>
								{promotionItems?.map?.((promotionItem, index) => {
									return (
										<Tr key={`${promotionItem?.id}_${index}`}>
											<PromotionEngineManageRow fieldsToHide={fieldsToHide}>
												{{
													promotionItem,
													redeemCount: promotionItem.redemption_count,
													APIHandler,
													underMutation,
													requestOpenViewClose: partialApply(
														viewEditCloseDeleteById,
														promotionItem.id,
													),
												}}
											</PromotionEngineManageRow>
										</Tr>
									);
								})}
								<Tr>
									<td
										colSpan={11}
										align="center"
										style={{ position: 'relative' }}
									>
										<StyledButton onClick={createRequestHandler}>
											Create New
										</StyledButton>
										<StyledPaginationDetails>
											{currentPage} of {maxPage} Pages
										</StyledPaginationDetails>
									</td>
								</Tr>
							</Tbody>
						</ManageTable>
					</div>
				</ScrollBox>
			)}
			{maxPage !== 1 && (
				<Pagination
					min={1}
					max={maxPage}
					EitherSide={2}
					pageRequest={pageChangeRequest}
					current={currentPage}
					preFetchPage={pagePreFetchRequest}
				/>
			)}
		</div>
	);
};

const StyledPaginationDetails = styled.span`
	position: absolute;
	right: 0;
	top: 0;
	transform: translateY(100%);
	padding-right: ${({ theme }) => theme.spacings.comfortable}px;
`;
export default PromotionEngineManageScreen;
