import React, { useRef } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { FiCheck, FiSave, FiX } from 'react-icons/fi';
import alertConfirm from 'react-alert-confirm';
import 'react-alert-confirm/dist/index.css';

import { contentValidation } from '../../common/validations';
import { UpdateDraft, CreateDraft, PublishDraft } from '../../store/pages';
import { zeroPad } from '../../helpers/index';
import {
	contentItemType,
	ContentItemsByStatus,
} from '../../helpers/newAPIClient';

import {
	Form,
	Holder,
	LabelWithError,
	Input,
	Divider,
} from '../shared/FormElements';
import Card from '../shared/Card';
import { NavGroup, StyledButton } from '../shared/Navs';
import MarkdownEditor from '../shared/MarkdownEditor';
import PageHeader from '../shared/PageHeader';
import { useBrandConfig } from '../../context/brandConfig/brandConfigContext';

type FormValues = contentItemType;

interface FormProps {
	toggleModal: any;
	selectedStatusID: string;
	initialValues: FormValues;
	UpdateDraft: (item: contentItemType) => Promise<any>;
	CreateDraft: (item: contentItemType) => Promise<any>;
	PublishDraft: (item: contentItemType) => Promise<any>;
	slugsTaken: string[];
}
const ContentForm = ({
	toggleModal,
	initialValues,
	UpdateDraft,
	CreateDraft,
	PublishDraft,
	slugsTaken,
}: FormProps) => {
	const { brandConfig } = useBrandConfig();

	const formik = useFormik({
		initialValues,
		onSubmit: async (values, { setSubmitting }) => {
			values.id !== '' ? await UpdateDraft(values) : await CreateDraft(values);
			toggleModal(true);
			setSubmitting(false);
		},
		enableReinitialize: true,
		validateOnMount: true,
		validationSchema: contentValidation(slugsTaken),
	});

	const isMobile = window.innerWidth <= 600;
	const contentTextArea = useRef();
	const formTitle =
		formik.values.id === ''
			? 'New Page'
			: isMobile
			? 'Edit page'
			: `Editing "${formik.values.title}"`;

	const formChanged = Object.keys(formik.values).some(
		(key) => formik.values[key] !== formik.initialValues[key],
	);
	const handleSaveClick = (e) => {
		e.preventDefault();
		formik.submitForm();
	};
	const handlePublishClick = async (e) => {
		e.preventDefault();
		if (formik.values.id !== '' && !formChanged)
			await PublishDraft(formik.values);
		toggleModal(false);
	};

	const handleClose = (e) => {
		if (formik.dirty) {
			alertConfirm({
				title: 'Unsaved content',
				content: 'Leave page? Unpublished changes will be lost',
				okText: 'Leave without saving',
				cancelText: 'Go back to form',
				onOk: () => {
					toggleModal(false);
				},
				onCancel: () => {},
			});
		} else {
			toggleModal(false);
		}
	};

	const slugPreview = `${brandConfig.appUrl}/content/${formik.values.slug}`;

	return (
		<Card isModal>
			<PageHeader title={formTitle}>
				<NavGroup>
					<StyledButton
						isactive={true}
						style={{ marginLeft: 0 }}
						disabled={formik.isSubmitting || !formik.isValid}
						onClick={handleSaveClick}
					>
						{!isMobile && 'Save '}
						<FiSave className="icon-only-mobile" />
					</StyledButton>
					<StyledButton
						isactive={true}
						hidden={formik.values.id === '' || formChanged}
						disabled={formik.values.id === '' || formChanged}
						onClick={handlePublishClick}
					>
						{!isMobile && 'Publish '}
						<FiCheck className="icon-only-mobile" />
					</StyledButton>
					<StyledButton onClick={handleClose}>
						{!isMobile && 'Close '}
						<FiX className="icon-only-mobile" />
					</StyledButton>
				</NavGroup>
			</PageHeader>
			<Divider />

			<Form onSubmit={formik.handleSubmit}>
				<Holder width="100%">
					<LabelWithError
						htmlFor="title"
						title="Title"
						error={formik.errors.title}
					>
						<Input
							id="title"
							value={formik.values.title}
							onChange={formik.handleChange}
							isError={!!formik.errors.title}
						/>
					</LabelWithError>
				</Holder>
				<Holder width={isMobile ? '100%' : '50%'}>
					<LabelWithError
						htmlFor="slug"
						title="Slug"
						error={formik.errors.slug}
						hint={slugPreview}
					>
						<Input
							id="slug"
							value={formik.values.slug}
							onChange={formik.handleChange}
							isError={!!formik.errors.slug}
						/>
					</LabelWithError>
				</Holder>
				<Holder width={isMobile ? '100%' : '50%'}>
					<LabelWithError
						htmlFor="date"
						title="Date"
						error={formik.errors.publish_date}
						hint="Select the publish date"
					>
						<Input
							id="publish_date"
							type="datetime-local"
							step="1"
							placeholder="YYYY-MM-DD HH:MM:SS"
							value={formik.values.publish_date}
							onChange={formik.handleChange}
							isError={!!formik.errors.publish_date}
						/>
					</LabelWithError>
				</Holder>
				<Holder width="100%">
					<LabelWithError
						htmlFor="content"
						title="Page Content"
						error={formik.errors.content}
					>
						<textarea
							hidden
							readOnly
							ref={contentTextArea}
							name="content"
							id="content"
							value={formik.values.content}
							onInput={formik.handleChange}
						/>
						<MarkdownEditor
							value={formik.values.content ?? ''}
							height="400px"
							handleChange={({ html, text }) => {
								const contentElement = contentTextArea.current as HTMLTextAreaElement;
								contentElement.value = text;
								contentElement.dispatchEvent(
									new Event('input', { bubbles: true }),
								);
							}}
							config={{
								view: { menu: true, md: true, html: isMobile ? false : true },
							}}
							styleType="banner"
						/>
					</LabelWithError>
				</Holder>
			</Form>
		</Card>
	);
};

const pagesToInitialformValue = (
	content: ContentItemsByStatus,
	status_id: string,
): contentItemType => {
	const now = new Date();
	const defaultValue: FormValues = {
		id: '',
		status: 'draft',
		status_id: '',
		pk: 'content',
		publish_date: `${now.getFullYear()}-${zeroPad(
			2,
			now.getMonth().toString(),
		)}-${zeroPad(2, now.getDate().toString())}T${zeroPad(
			2,
			now.getHours().toString(),
		)}:${zeroPad(2, now.getMinutes().toString())}`,
		slug: '',
		title: '',
		content: '',
	};
	const selectedPage =
		Object.keys(content)
			.flatMap((key) => [
				...(content[key].published ? [content[key].published] : []),
				...(content[key].draft ? [content[key].draft] : []),
			])
			.map((item) => ({
				...item,
				publish_date: item.publish_date.replace(' ', 'T'),
			}))
			.find((item) => status_id === `${item.status}_${item.id}`) ||
		defaultValue;
	return selectedPage;
};

const mapStateToProps = (state) => ({
	content: state.pages.data,
	selectedStatusID: state.pages.selectedStatusID,
	initialValues: pagesToInitialformValue(
		state.pages.data,
		state.pages.selectedStatusID,
	),
	slugsTaken: Object.entries(state.pages.data as ContentItemsByStatus)
		.filter(
			([key, _value]) => key !== state.pages.selectedStatusID?.split('_')?.[1],
		)
		.flatMap(([Key, { draft, published }]) => [
			...(draft ? [draft.slug] : []),
			...(published ? [published.slug] : []),
		]),
});

export default connect(mapStateToProps, {
	UpdateDraft,
	CreateDraft,
	PublishDraft,
})(ContentForm);
